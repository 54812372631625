import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import {
  downloadMarginOverviewApi,
  latestUpdateOnApi,
  SymphonyWithdrawalBalance
} from "../../helpers/fakebackend_helper";

// image
import WithdrawFunds from "../../Components/CustomComponents/Models/withDraw/WithdrawFunds";
import loaderImage from "../../assets/images/Loader.png";

const BreakDown = (props) => {
  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);
  const [isDownload, setIsDownload] = useState(false);
  const [file, setFile] = useState([]);
  const [updateData, setUpdateData] = useState("");
  const { generatedKey, encryptKey } = props;

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const symphony_token = localStorage.getItem("symphony_token");
  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() - 30);
  const [selectDate, setSelectDate] = useState({
    date: [endDate.setDate(endDate.getDate() - 30), startDate],
  });

  const [loading, setLoading] = useState(false);


  const [cashBalance, setcashBalance] = useState(0);
  const [collatral, setcollatral] = useState(0);
  const [marginUsed, setmarginUsed] = useState(0);
  const [TotalValue, setTotalValue] = useState(0);

  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch]);

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));

  console.log("client_code", client_code);
  useEffect(() => {
    setLoading(true)
    SymphonyWithdrawalBalance({
      client_token:client_token,
      token: symphony_token,
    })
      .then((response) => {
        console.log("SymphonyWithdrawalBalance99",response);
        console.log("SymphonyWithdrawalBalance99",response.balance.withdrawal);
        if (!response.balance_arr.total_margin) {
          setTotalValue(0);
          setcollatral(0);
          setcashBalance(0);
          setcashBalance(0);
    setLoading(false)

        } else {
          setTotalValue(parseFloat(response.balance_arr.total_margin));
          setcollatral(parseFloat(response.balance_arr.DirectCollateral));
          setcashBalance(parseFloat(response.balance_arr.CashMarginAvailable));
          setmarginUsed(parseFloat(response.balance_arr.MarginUsed_one));
    setLoading(false)

        }
      })
      .catch((err) => {
        setWithdrawalBalance(0);
        // console.log(err);
      });
}, [generatedKey, encryptKey]);


  const addFundHandler = () => {
    window.open(
      `https://payment.bigul.co/?ClientCode=${client_code}`,
      "_blank"
    );
  };

  const amountFormatter = (data) => {
    //console.log(data)
    let Total = data;
    if (data > 0) {
      let t = Math.round(Total * 100) / 100
      return `- ₹${t.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      let t = Math.round(Total * 100) / 100
      return `₹${t.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      let t = Math.round(Total * 100) / 100
      return `₹${t.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  const amountFormatter3 = (data) => {
    //console.log(data)
    let Total = data;
    if (data > 0) {
      let t = Math.round(Total * 100) / 100
      //console.log(t)
      return `₹${t.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      let t = Math.round(Total * 100) / 100
      return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      let t = Math.round(Total * 100) / 100
      return `₹${t.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  const amountFormatter2 = (data, data2) => {
    let Total = 0;
    if (data > 0) {
      Total =  -Math.abs(data)  + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      }else if(t == 0){
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }
      console.log(Total);

    } else if (data < 0) {
      ////console.log(data);
      Total = Math.abs(data) + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      }else if(t == 0){
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }

    } else {
      Total = data + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      }else if(t == 0){
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }
      //console.log(Total);

    }
    //  ////console.log(data);
  };


  useEffect(() => {
    const fetchData = async () => {
      await latestUpdateOnApi({ clientCode: client_code })
        .then((response) => {
          setUpdateData(response.createdAt);
          ////console.log(response);
        })
        .catch((err) => {
          ////console.log(err);
        });
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const openState = {
    CUpdate: true,
    otp1: false,
    done: false,
  };

  const downloadHandler = () => {
    downloadMarginOverviewApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"),
      endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setFile(response.finResult);
        setIsDownload(true);
        ////console.log(response);
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  const myArray = [];
  for (let i = 0; i < file.length; i += 1) {
    const childArray = file[i];
    const obj = {
      Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      Amount: childArray.Damount,
      Balance: childArray.Camount,
    };
    myArray.push(obj);
  }

  const userHeader = ["Name", "Date", "Amount", "Balance"];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `Ledger`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const referHandler = () => {
    window.open(
      `https://bigul.co/en/index.php/terms-and-conditions/client-referral-terms-and-conditions/`,
      "_blank"
    );
  };

  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push((el.CollateralQuantity * el.mktrate) - ((el.CollateralQuantity * el.mktrate* el.Haircut) / 100));
    }
    return el.CollateralQuantity > 0;
  });
  //console.log(CollateralNew)
  // //console.log(CollateralValues)
  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  //console.log(CollateralValuessubTotal);

  //const TotalValue = availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  ////console.log(TotalValue);
  const a = 8;
  const b = 4
  
  // let TotalValue = 0;
  // if (availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 > 0 ) {
  //   TotalValue = -Math.abs(+availableMargin.availableMargin[0].Final1) + CollateralValuessubTotal;
  // } else if (availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 < 0 ) {
  //   TotalValue = Math.abs(+availableMargin.availableMargin[0].Final1) + CollateralValuessubTotal;
  // } else if (availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 === 0) {
  //   TotalValue = +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal
  // }
  //console.log(TotalValue);

  return (
    <Card style={{ height: "250px" }}>
      <CardHeader className="border-0 d-flex align-items-center custom-border3">
        <div style={{ height: "46px" }} className="d-flex align-items-center">
          <h4 className="text-6 text-color1 mb-0 flex-grow-1">Bifurcation</h4>
        </div>
      </CardHeader>
      <div className="card-body px-4">
        <div className="d-flex justify-content-between align-items-center text-color7 mb-4 mt-2">
          <p className="m-0 text-20 text-color1">Cash Balance :</p>
          <p
            className={`m-0 text-20 text-color1 ${
              cashBalance > 0 ? "text-color6" : "text-color11"
            }`}
          >
            {
             loading ?
                <div style={{display:'flex',justifyContent:'center'}}>
                  <img src={loaderImage} className="custom-loader" style={{ height: "20px",}} />
                   </div>
                  : '₹' + TotalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center text-color7 mb-4 mt-2">
          <p className="m-0 text-20 text-color1">Collateral :</p>
          <div className="">
            <p className={`m-0 text-20 text-color1 text-end ${
          collatral > 0 ? "text-color6" : "text-color11"
        }`}> ₹{collatral.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
            {/* <p className="m-0 text-21 text-color1 text-end">[ <a href="#">Pledge</a> | <a href="#">Unpledge</a> ]</p> */}
            {/* <p className="m-0 text-21 text-color1 text-end">[ <Link to="/pledge">Pledge</Link> ]</p>  */}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center text-color7">
          <p className="m-0 text-20 text-color1">Margin Used :</p>
          <div className="">
            <p className={`m-0 text-20 text-color1 text-end text-color11`}> {marginUsed<=0 ?'':'-'}₹{marginUsed}</p>
            {/* <p className="m-0 text-21 text-color1 text-end">[ <a href="#">Pledge</a> | <a href="#">Unpledge</a> ]</p> */}
            {/* <p className="m-0 text-21 text-color1 text-end">[ <Link to="/pledge">Pledge</Link> ]</p>  */}
          </div>
        </div>
        {/* <div className="d-flex justify-content-between align-items-center text-color7">
          <p className="m-0 text-20 text-color1">Margin :</p>
          <p className="m-0 text-20 text-color1">{amountFormatter(
              0
            )}</p>
        </div> */}
      </div>
      {/* <div className="custom-border4 d-flex justify-content-between py-2">
        <h4 className="text-6 text-color1 py-2 px-4 mb-0">Total:</h4>
        <h4 
        className={` text-6 py-2 px-4 mb-0 ${
          TotalValue > 0
            ? "text-color6"
            : TotalValue < 0 ? "text-color11" : "text-color1"
        }`}>₹{TotalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
      </div> */}
    </Card>
  );
};

export default BreakDown;
