import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConformUpdate from './ConformUpdate';
import VerificationNumber from './VerificationNumber';
import LinkNewMobile from './LinkNewMobile';
import VerifyMobile from './VerifyMobile';
import MobileLinkedCompleted from './MobileLinkedCompleted';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import {useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
import {checkModificationStatusNew } from "../../../../helpers/fakebackend_helper";

const MobileNumberEditIndex = (props) => {
  const dispatch = useDispatch();
  const { openState } = props;

  useEffect(() => {
    ////console.log('reset')
    dispatch(ResetPhoneState());
  }, [])

  useEffect(() => {
    setModelMobile(openState)
  },[props])
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalMobile, setModelMobile] = useState({});
 

  const sendData = (data) => {
  
    setModelMobile(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
  
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    setModelMobile(openState)
    setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="text-11 text-color7 profile-edit-button1"
        //onClick={() => tog_signUpModals()}
        onClick={() => {
          checkModificationStatusNew(props.data2)
            .then((response) => {
           //   //console.log(response);
              if (response.status === true && response?.data?.modification?.length > 0) {
                toast.info("Already we have one request for mobile update", {
                  autoClose: 1500,
                })
              } else {
                tog_signUpModals();
              }
              //props.sendData(modalMobile);
            })
            .catch((err) => {
             toast('something went wrong')
            });
        }}
      >
        Edit
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalMobile.CUpdate && <ConformUpdate sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.otp1 && <VerificationNumber state={props.state} onStateChange={props.onStateChange}  sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.vNumber1 && <LinkNewMobile state={props.state} onStateChange={props.onStateChange}  sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.vNumber2 && <VerifyMobile sendData={sendData} tog={tog_signUpModals} name={props?.name} />  }
        {modalMobile.done && <MobileLinkedCompleted state={props.state} onStateChange={props.onStateChange}  sendData={tog_signUpModalsFinal} name={props?.name} />}
      </Modal>
    </React.Fragment>
  );
};

export default MobileNumberEditIndex;
