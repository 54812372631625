import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getHighlights } from "../../store/bigulHighlights/action";

import { topCollectionData } from "../../common/data/dashboardNFT";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// Images
import highlights_bg from "../../assets/images/logo/highlights_bg.png";
import highlights_icon from "../../assets/images/logo/highlights_icon.png";

const Highlights = () => {
  const dispatch = useDispatch();

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const { highlightsDetails } = useSelector((state) => ({
    highlightsDetails: state.Highlights.highlightsDetails,
  }));

  /*
  get data
  */
  useEffect(() => {
    dispatch(
      getHighlights({ client_code: client_code, client_token: client_token })
    );
  }, [dispatch, client_code, client_token]);

  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };
  const externalUrlRedirectHandler = (data) => {
    window.open(data, "_blank");
  };
  return (
    <React.Fragment>
      <Col
        xxl={3}
        xl={3}
        lg={6}
        md={6}
        sm={6}
        className="spacing-remove-demounting1"
      >
        <Card className="card-header-highlight card-height-101 m-0">
          <div
            style={{
              backgroundImage: `url(${highlights_bg})`,
              backgroundSize: "contain",
              overflow: "hidden",
            }}
          >
            <h6 className="text-6 text-center highlights-header mt-2">
              Bigul Highlights
            </h6>

            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind
              showDots
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {highlightsDetails.length > 0 &&
                highlightsDetails.map((item, key) => (
                  <CardBody
                    key={key}
                    style={{ height: "280px" }}
                    className="d-flex flex-column justify-content-center"
                  >
                    {/* <div
                      
                      className="dash-collection overflow-hidden rounded-top position-relative"
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    > */}
                    <img
                      src={item.logo || highlights_icon}
                      alt={item.slug}
                      height="60"
                      style={{ margin: "0 auto", borderRadius: "12px" }}
                      //className="object-cover w-100"
                    />
                    <h6
                      className="mt-3 text-6 highlights-slider-header"
                      style={{ height: "auto", paddingBottom: "10px" }}
                    >
                      {/* {item.title} */}
                      {/* {charactersLimit(item.title, 25)} */}
                      {item.title}
                    </h6>
                    <p
                      className="text-1 highlights-slider-header mb-2"
                      style={{ height: "70px" }}
                    >
                      {charactersLimit(item.short_description, 80)}
                    </p>
                    <div style={{ margin: "0 auto" }}>
                      {item.url && (
                        <button
                          onClick={() => {
                            externalUrlRedirectHandler(item.url);
                          }}
                          className="text-4 highlights-slider-button3 spacing-bottom-p"
                        >
                          Read now
                        </button>
                      )}
                    </div>
                    {/* </div> */}
                  </CardBody>
                ))}
            </Carousel>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Highlights;
