import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  FormFeedback,
} from "reactstrap";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//formik
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import NomineeDetailsModelIndex from "../../../Components/CustomComponents/Models/nomineeDetails/NomineeDetailsModelIndex";
import {
  AddNomineeApiCall,
  DDPIShameESign,
  checkModificationStatus,
  AfterESignDeduction,
} from "../../../helpers/fakebackend_helper";
import dayjs from "dayjs";
import axios from "axios";
var nominee_Data = []
const NomineeDetailsAdd = (props) => {
  const [finalValue, setFinalValue] = useState({});
  const { data, data2 } = props;
  const [formValue, setFormValue] = useState({ nominee: props?.oldData?.length > 0 ? props?.oldData : [], Percentage: 0 });
  const [percentageValue, setPercentageValue] = useState(100);
  const [isEdited, setIsEdited] = useState(false);

  let emptyNomineeRow = {
    name: "",
    nominee_email_1: "",
    nominee_mobile_number_1: "",
    nominee_relation_1: "",
    proof_type_1: "",
    proof_num_1: "",
    nominee_dob_1: undefined,
    nominee_proof_type_1: "",
    nominee_proof_file_1: "",
    file: "",
    same_as_flag_1: false,
    address_line_1_1: "",
    address_line_2_1: "",
    area_code_1: "",
    city_1: "",
    state_1: "",
    country_1: "",
    city_1: "",
    percentage_1: 0,
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ValidationSchema = Yup.object().shape({
    nominee: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        nominee_email_1: Yup.string()
          .email("Must be a valid email ID")
          .required("Required"),
        nominee_mobile_number_1: Yup.number()
          // .matches(phoneRegExp, "Enter Valid Mobile Number")
          .min(
            6000000000,
            "Mobile number must start with a digit between 6 and 9 and be 10 digits long."
          )
          .max(9999999999, "Mobile number not more then 10 digits.")
          .required("This field is required"),
        nominee_relation_1: Yup.string().required("Required"),
        proof_type_1: Yup.string().required("Required"),
        nominee_dob_1: Yup.string().required("Required"),
        // proof_num_1: Yup.string().required("Required"),
        proof_num_1: Yup.string()
          .when('proof_type_1', {
            is: 'Aadhar',
            then: Yup.string()
              .matches(/^[0-9]{12}$/, 'Aadhar must be exactly 12 digits long.')
              .required('Required'),
            otherwise: Yup.string()
              .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'PAN must be in the format AAAAA9999A.')
              .required('Required')
          }),

        address_line_1_1: Yup.string()
          .min(3, "Address Line 1 must be at least 3 characters")
          .max(45, "Address Line 1 must be at most 45 characters")
          .required("Required"),
        address_line_2_1: Yup.string()
          .min(3, "Address Line 2 must be at least 3 characters")
          .max(45, "Address Line 2 must be at most 45 characters")
          .required("Required"),
        area_code_1: Yup.string().required("Required"),
        city_1: Yup.string().required("Required"),
        state_1: Yup.string().required("Required"),
        country_1: Yup.string().required("Required"),
        city_1: Yup.string().required("Required"),
        percentage_1: Yup.number()
          .min(1, "Minimum 1%")
          .max(100, "Minimum of 100%")
          .required("Required"),
      })
    ),
    // .required("Must have 1 Nominee")
    // .min(1, "Minimum of 3 Nominee"),
    Percentage: Yup.number()
      .min(100, 'Total Percentage should be 100%')
      .max(100, 'Total Percentage should be 100%')
      .required("Required"),
  });
  const client_code = localStorage.getItem("client_id");

  const formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const relations = [
    { id: 1, label: 'Spouse' },
    { id: 2, label: 'Son' },
    { id: 3, label: 'Daughter' },
    { id: 4, label: 'Father' },
    { id: 5, label: 'Mother' },
    { id: 6, label: 'Brother' },
    { id: 7, label: 'Sister' },
    { id: 8, label: 'Grand Son' },
    { id: 9, label: 'Grand Daughter' },
    { id: 10, label: 'Grand Father' },
    { id: 11, label: 'Grand Mother' },
    { id: 12, label: 'Not Provided' },
    { id: 13, label: 'Others' },
  ];


  return (
    <div className=" my-4">
      <Formik
        initialValues={formValue}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, errors) => {
          //console.log(values);
          const obj = {};
          if (values.nominee.length > 0) {
            for (let i = 0; i < values.nominee.length; i++) {
              obj[`nominee_name_${i + 1}`] = values.nominee[i].name;
              obj[`nominee_email_${i + 1}`] = values.nominee[i].nominee_email_1;
              obj[`nominee_mobile_number_${i + 1}`] =
                values.nominee[i].nominee_mobile_number_1;
              obj[`nominee_relation_${i + 1}`] =
                values.nominee[i].nominee_relation_1;
              obj[`proof_type${i + 1}`] =
                values.nominee[i].proof_type_1;
              obj[`nominee_dob_${i + 1}`] = values.nominee[i].nominee_dob_1;
              obj[`nominee_proof_type_${i + 1}`] =
                values.nominee[i].nominee_proof_type_1;
              obj[`proof_num_${i + 1}`] =
                values.nominee[i].proof_num_1;
              obj[`nominee_proof_file_${i + 1}`] =
                values.nominee[i].nominee_proof_file_1;
              obj[`same_as_flag_${i + 1}`] =
                values.nominee[i].same_as_flag_1 === true ? 1 : 2;
              obj[`address_line_1_${i + 1}`] =
                values.nominee[i].address_line_1_1;
              obj[`address_line_2_${i + 1}`] =
                values.nominee[i].address_line_2_1;
              obj[`area_code_${i + 1}`] = values.nominee[i].area_code_1;
              obj[`city_${i + 1}`] = values.nominee[i].city_1;
              obj[`state_${i + 1}`] = values.nominee[i].state_1;
              obj[`country_${i + 1}`] = values.nominee[i].country_1;
              obj[`percentage_${i + 1}`] = values.nominee[i].percentage_1;
            }
          }
          //console.log(obj);
          const newValue = {
            ...obj,
            client_code: client_code,
            pan_no: data2?.PANGIR,
            nominee_total: values.nominee.length,
          };
          setFinalValue(newValue);
          console.log(newValue, 'newvalue');

        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          touched,

          handleBlur,
        }) => {


          nominee_Data = {
            'pan_no': data2?.PANGIR,
            'nomini_val': values?.nominee
          }
          console.log(nominee_Data, 'nominee_Data')
          console.log(values, 'values')

          setFormValue(values);

          const sumall =
            values.nominee.length > 0 && values.nominee.length > 1
              ? values.nominee
                .map((item) => {
                  return Number(item.percentage_1);
                })
                .reduce((prev, curr) => prev + curr, 0)
              : values.nominee.length > 0 && values.nominee.length <= 1
                ? values.nominee[0].percentage_1
                : 0;


          if (values.Percentage !== sumall) {
            setFieldValue("Percentage", sumall);
          }


          if (
            !isEmpty(errors) &&
            errors.nominee &&
            errors.nominee.length > 0 &&
            errors.nominee[0] !== undefined
          ) {
            //console.log(errors.nominee);
          } else {
            //console.log(errors);
          }
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <FieldArray name="nominee" className="">
                {({ push, remove }) => (
                  <>
                    {values.nominee.map((_, index) => (
                      <div className="form-group row" key={index}>
                        {values.nominee.length == 1 && <Row > <div className="">
                          <span>
                            <a
                              className="btn additional-page-button-save222 me-0 mt-2"
                              onClick={() =>
                                remove(index)
                              }
                            >
                              Go Back
                            </a>
                          </span>
                          <hr />
                        </div></Row>}
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Nominee’s Name
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4 form-control"
                              id="nameInput"
                              placeholder="Full Name"
                              name={`nominee[${index}].name`}
                              //value={`nominee[${index}].name`}
                              value={values.nominee[index].name}
                              // onBlur={validation.handleBlur}
                              // onChange={handleChange}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].name`,
                                  e.target.value
                                );
                                // setFieldValue(
                                //   `nominee[${index}].proof_num_1`,
                                //   ''
                                // );
                              }}
                              onBlur={async () => {
                                var date = values.nominee[index].nominee_dob_1
                                const dateformate = date.replace(/-/g, '/');
                                const panNo = values.nominee[index].proof_num_1
                                if (values.nominee[index].proof_type_1 == "Pan" && values.nominee[index].nominee_dob_1 != " " && values.nominee[index].proof_num_1.length > 0) {
                                  try {
                                    const response = await axios.post(`https://apinew.bigul.app:4040/api/v1/directPanApiCheck`, {

                                      PanNo: panNo,
                                      dob: dateformate,
                                      name: values.nominee[index].name,

                                    });
                                    if (response.status == true) {

                                    } else {
                                      toast.error(response.message)
                                      if (response.message == 'Please enter Name as per PAN.') {
                                        setFieldValue(
                                          `nominee[${index}].name`,
                                          ''
                                        );
                                        return

                                      }
                                      if (response.message == 'DOB does not match with PAN.') {
                                        setFieldValue(
                                          `nominee[${index}].nominee_dob_1`,
                                          undefined
                                        );
                                        return

                                      }
                                    }

                                  } catch (error) {

                                    toast.error('Error validating PAN. Please try again.');
                                  }
                                }
                              }}

                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].name
                                  ? true
                                  : false
                              }
                            />
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].name}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="websiteUrl"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Nominee’s Email ID
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="websiteUrl"
                              placeholder="Email ID"
                              //name="nominee_email_1"
                              name={`nominee[${index}].nominee_email_1`}
                              value={values.nominee[index].nominee_email_1}
                              // onChange={(e) => {
                              //   setFieldValue(
                              //     `nominee[${index}].nominee_email_1`,
                              //     e.target.value
                              //   );
                              // }}
                              onChange={(e) => {
                                const re = /^[a-z0-9][a-z0-9.@\-]*$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setFieldValue(
                                    `nominee[${index}].nominee_email_1`,
                                    e.target.value
                                  );
                                }
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].nominee_email_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].nominee_email_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Nominee’s Mobile Number
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              maxLength={10}
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="Mobile Number"
                              value={
                                values.nominee[index].nominee_mobile_number_1
                              }
                              //name="nominee_mobile_number_1"
                              name={`nominee[${index}].nominee_mobile_number_1`}
                              onChange={(e) => {

                                const re = /^[1-9][0-9]*$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setFieldValue(
                                    `nominee[${index}].nominee_mobile_number_1`,
                                    e.target.value
                                  );
                                }
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].nominee_mobile_number_1
                                  ? true
                                  : false
                              }


                            />
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].nominee_mobile_number_1}
                              </FormFeedback>
                            ) : null}

                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Nominee’s Relation
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                              id="nameInput"
                              //name="nominee_relation_1"
                              type="select"
                              name={`nominee[${index}].nominee_relation_1`}
                              value={values.nominee[index].nominee_relation_1}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].nominee_relation_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].nominee_relation_1
                                  ? true
                                  : false
                              }

                            >
                              <option value="" disabled>
                                Select Relation
                              </option>
                              {relations?.map((relation) => (
                                <option key={relation.id} value={relation.id}>
                                  {relation.label}
                                </option>
                              ))}
                              {/* <option key="" value="" disabled>
                                Select Relation{" "}
                              </option>
                              <option key="Spouse" value="Spouse">
                                Spouse
                              </option>
                              <option key="Son" value="Son">
                                Son
                              </option>
                              <option key="Daughter" value="Daughter">
                                Daughter
                              </option>
                              <option key="Father" value="Father">
                                Father
                              </option>
                              <option key="Mother" value="Mother">
                                Mother
                              </option>
                              <option key="Brother" value="Brother">
                                Brother
                              </option>
                              <option key="Sister" value="Sister">
                                Sister
                              </option>
                              <option key="Grand Son" value="Grand Son">
                                Grand Son
                              </option>
                              <option key="Grand Daughter" value="Grand Daughter">
                                Grand Daughter
                              </option>
                              <option key="Grand Father" value="Grand Father">
                                Grand Father
                              </option>
                              <option key="Grand Mother" value="Grand Mother">
                                Grand Mother
                              </option>
                              <option key="Not Provided" value="Not Provided">
                                Not Provided
                              </option>
                              <option key="others" value="others">
                                Others
                              </option> */}
                            </Input>
                            {/* {!isEmpty(errors) &&
                          !isEmpty(touched) &&
                          errors.nominee &&
                          touched.nominee[index].nominee_relation_1 &&
                          errors.nominee[index].nominee_relation_1 ? (
                            <FormFeedback type="invalid">
                              {errors.nominee[index].nominee_relation_1}
                            </FormFeedback>
                          ) : null} */}
                            {/* {validation.errors.nominee_relation_1 &&
                        validation.touched.nominee_relation_1 ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nominee_relation_1}
                          </FormFeedback>
                        ) : null} */}
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].nominee_relation_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>



                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Proof type
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                              id="nameInput"

                              type="select"
                              name={`nominee[${index}].proof_type_1`}
                              value={values.nominee[index].proof_type_1}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].proof_type_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].proof_type_1
                                  ? true
                                  : false
                              }

                            >
                              <option key="" value="" disabled>
                                Select proof type{" "}
                              </option>
                              <option key="Pan" value="Pan">
                                Pan
                              </option>
                              <option key="Aadhar" value="Aadhar">
                                Aadhar
                              </option>

                            </Input>

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].proof_type_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Nominee’s DOB
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Flatpickr
                              name={`nominee[${index}].nominee_dob_1`}
                              id="date-field"
                              className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                              placeholder="Select a date"
                              options={{
                                dateFormat: "d-m-Y",  // Set date format to DD-MM-YYYY
                                maxDate: new Date(),  // Max date is today
                              }}
                              value={values.nominee[index].nominee_dob_1}
                              onChange={(selectedDates) => {
                                const date = selectedDates[0];  // Get the selected date
                                const formattedDate = formatDate(date);  // Format the date
                                setFieldValue(
                                  `nominee[${index}].nominee_dob_1`,
                                  formattedDate
                                );
                              }}

                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].nominee_dob_1
                                  ? true
                                  : false
                              }
                            />
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].nominee_dob_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Proof ID
                            </Label>
                          </Col>

                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              placeholder=" Proof ID"
                              value={values.nominee[index].proof_num_1}
                              maxLength={values.nominee[index].proof_type_1 == 'Pan' ? 10 : 12}
                              name={`nominee[${index}].proof_num_1`}
                              onChange={async (e) => {
                                const value = e.target.value.toUpperCase();
                                const proofType = values.nominee[index].proof_type_1;
                                setFieldValue(`nominee[${index}].proof_num_1`, value);

                                if (value.length > 9 && proofType === 'Pan') {

                                  var date = values.nominee[index].nominee_dob_1

                                  const dateformate = date.replace(/-/g, '/');
                                  try {
                                    const response = await axios.post(`https://apinew.bigul.app:4040/api/v1/directPanApiCheck`, {

                                      PanNo: value,
                                      dob: dateformate,
                                      name: values.nominee[index].name,

                                    });
                                    if (response.status == true) {

                                    } else {
                                      toast.error(response.message)
                                      if (response.message == 'Please enter Name as per PAN.') {
                                        setFieldValue(
                                          `nominee[${index}].name`,
                                          ''
                                        );
                                        return

                                      }
                                      if (response.message == 'DOB does not match with PAN.') {
                                        setFieldValue(
                                          `nominee[${index}].nominee_dob_1`,
                                          undefined
                                        );
                                        return

                                      }
                                    }

                                  } catch (error) {

                                    setFieldValue(`nominee[${index}].proof_num_1`, e.target.value);
                                    alert('Error validating PAN. Please try again.');
                                  }
                                }
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].proof_num_1
                                  ? true
                                  : false
                              }
                            />
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].proof_num_1}
                              </FormFeedback>
                            ) : null}
                          </Col>

                        </Row>





                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >

                              Allocated Percentage
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="number"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="Percentage"
                              //name="percentage_1"
                              min={1}
                              max={100}
                              value={
                                // percentageValue
                                //  100/ values.nominee.length
                                values.nominee[index].percentage_1 > 0
                                  ? Number(values.nominee[index].percentage_1)
                                  : ""
                              }
                              name={`nominee[${index}].percentage_1`}
                              onChange={(e) => {
                                if (Number(e.target.value) <= 0) {
                                  e.target.value = 0;
                                }
                                // setPercentageValue(e.target.value);

                                //setFieldValue('Percentage', values.Percentage + +e.target.value)
                                const re = 100;
                                if (
                                  e.target.value === "" ||
                                  +e.target.value <= re
                                ) {
                                  setIsEdited(true);
                                  setFieldValue(
                                    `nominee[${index}].percentage_1`,
                                    Number(e.target.value).toFixed(2)
                                  );
                                }
                                // setFieldValue(
                                //   `nominee[${index}].percentage_1`,
                                //   +e.target.value
                                // );
                              }}
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].percentage_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].percentage_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3" style={{ display: "none" }}>
                          <Col lg={3}>isEdited</Col>
                          <Col lg={3}>{isEdited ? 'true' : 'false'}</Col>
                        </Row>
                        <Row className="mb-3" style={{ display: "none" }}>
                          <Col lg={3}>
                            <Label
                              htmlFor={`nameInput${index}`}
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Same as my address
                            </Label>
                          </Col>
                          <Col lg={6} className="d-flex align-items-center">
                            <Input
                              type="checkbox"
                              className="me-3 p-3"
                              id={`nameInput${index}`}

                              value={values.nominee[index].same_as_flag_1}
                              checked={values.nominee[index].same_as_flag_1}
                              name={`nominee[${index}].same_as_flag_1`}
                              onChange={(e) => {
                                //console.log(e.target.checked);
                                if (e.target.checked === true) {
                                  setFieldValue(
                                    `nominee[${index}].same_as_flag_1`,
                                    true
                                  );
                                  setFieldValue(
                                    `nominee[${index}].address_line_1_1`,
                                    data2?.ADDRESS1
                                  );
                                  setFieldValue(
                                    `nominee[${index}].address_line_2_1`,
                                    data2?.ADDRESS2
                                  );
                                  setFieldValue(
                                    `nominee[${index}].area_code_1`,
                                    data2?.PINCODE
                                  );
                                  setFieldValue(
                                    `nominee[${index}].city_1`,
                                    data2?.CITY
                                  );
                                  setFieldValue(
                                    `nominee[${index}].state_1`,
                                    data2?.CSTATE
                                  );
                                  setFieldValue(
                                    `nominee[${index}].country_1`,
                                    data2?.COUNTRY
                                  );
                                } else {
                                  setFieldValue(
                                    `nominee[${index}].same_as_flag_1`,
                                    false
                                  );
                                  setFieldValue(
                                    `nominee[${index}].address_line_1_1`,
                                    ""
                                  );
                                  setFieldValue(
                                    `nominee[${index}].address_line_2_1`,
                                    ""
                                  );
                                  setFieldValue(
                                    `nominee[${index}].area_code_1`,
                                    ""
                                  );
                                  setFieldValue(`nominee[${index}].city_1`, "");
                                  setFieldValue(
                                    `nominee[${index}].state_1`,
                                    ""
                                  );
                                  setFieldValue(
                                    `nominee[${index}].country_1`,
                                    ""
                                  );
                                }
                                setFieldValue(
                                  `nominee[${index}].same_address`,
                                  e.target.checked
                                );
                              }}

                            />

                            <h4 className="text-color1 text-4 margin-0 ">
                              {`${data2?.ADDRESS1}, ${data2?.ADDRESS2}, ${data2?.ADDRESS3}, ${data2?.CITY}, ${data2?.COUNTRY}, ${data2?.CSTATE}-${data2?.PINCODE}`}
                            </h4>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Address 1
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="Address Line 1"
                              maxLength={45}

                              //name="address_line_1_1"
                              name={`nominee[${index}].address_line_1_1`}
                              value={values.nominee[index].address_line_1_1}
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].address_line_1_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].address_line_1_1
                                  ? true
                                  : false
                              }

                            />
                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].address_line_1_1}
                              </FormFeedback>
                            ) : null}

                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Address 2
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              maxLength={45}
                              placeholder="Address Line 2"
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              //name="address_line_2_1"
                              value={values.nominee[index].address_line_2_1}
                              name={`nominee[${index}].address_line_2_1`}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].address_line_2_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].address_line_2_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].address_line_2_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Area Code
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="number"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="Area Code"
                              // name="area_code_1"
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              name={`nominee[${index}].area_code_1`}
                              value={values.nominee[index].area_code_1}
                              onChange={async (e) => {
                                setFieldValue(
                                  `nominee[${index}].area_code_1`,
                                  e.target.value
                                );

                                console.log('nominee.area_code_1', e.target.value)
                                console.log('nominee.area_code_1', (e.target.value).length)


                                if ((e.target.value).length > 5) {
                                  try {
                                    const response = await axios.get(`https://bigul.co/api/pincodegetstatecity?pincode=` + e.target.value, []);
                                    if (response.status == true) {
                                      console.log('adasd', response.state)
                                      setFieldValue(
                                        `nominee[${index}].city_1`,
                                        response.city
                                      );
                                      setFieldValue(
                                        `nominee[${index}].state_1`,
                                        response.state
                                      );
                                      setFieldValue(
                                        `nominee[${index}].country_1`,
                                        'India'
                                      );
                                    } else {
                                      setFieldValue(
                                        `nominee[${index}].city_1`,
                                        ''
                                      );
                                      setFieldValue(
                                        `nominee[${index}].state_1`,
                                        ''
                                      );
                                      setFieldValue(
                                        `nominee[${index}].country_1`,
                                        ''
                                      );
                                    }

                                  } catch (error) {


                                  }
                                } else {
                                  setFieldValue(
                                    `nominee[${index}].city_1`,
                                    ''
                                  );
                                  setFieldValue(
                                    `nominee[${index}].state_1`,
                                    ''
                                  );
                                  setFieldValue(
                                    `nominee[${index}].country_1`,
                                    ''
                                  );
                                }
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].area_code_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].area_code_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              City
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="City"
                              //name="city_1"
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              name={`nominee[${index}].city_1`}
                              value={values.nominee[index].city_1}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].city_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].city_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].city_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              State
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="State"
                              //name="state_1"
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              name={`nominee[${index}].state_1`}
                              value={values.nominee[index].state_1}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].state_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].state_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].state_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="py-2 m-0 text-101 text-color5"
                            >
                              Country
                            </Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="text"
                              className="nominee-form-input text-color1 text-4"
                              id="nameInput"
                              placeholder="Country"
                              //name="country_1"
                              readonly
                              disabled={
                                values.nominee[index].same_as_flag_1 === true
                              }
                              name={`nominee[${index}].country_1`}
                              value={values.nominee[index].country_1}
                              onChange={(e) => {
                                setFieldValue(
                                  `nominee[${index}].country_1`,
                                  e.target.value
                                );
                              }}
                              invalid={
                                !isEmpty(errors) && !isEmpty(touched) &&
                                  errors.nominee &&
                                  errors.nominee.length > 0 &&
                                  errors.nominee[index] !== undefined &&
                                  errors.nominee[index].country_1
                                  ? true
                                  : false
                              }

                            />

                            {!isEmpty(errors) && !isEmpty(touched) &&
                              errors.nominee &&
                              errors.nominee.length > 0 &&
                              errors.nominee[index] !== undefined ? (
                              <FormFeedback type="invalid">
                                {errors.nominee[index].country_1}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <div className="">
                          <span>
                            <a
                              className="btn additional-page-button-save223 compleate-order-btn"
                              onClick={() => {
                                if (isEdited === false) {
                                  if (values.nominee.length == 2) {
                                    setFieldValue(
                                      `nominee[0].percentage_1`,
                                      100
                                    );
                                  }
                                  else if (values.nominee.length == 3) {
                                    setFieldValue(
                                      `nominee[0].percentage_1`,
                                      50
                                    );
                                    setFieldValue(
                                      `nominee[1].percentage_1`,
                                      50
                                    );
                                  }
                                }

                                remove(index);
                              }

                              }
                            >
                              Remove
                            </a>
                          </span>
                          <hr />
                        </div>
                      </div>
                    ))}
                    {values.nominee.length > 0 && (
                      <Row className="mb-3">
                        <Col lg={3}>
                          <Label
                            htmlFor="nameInput"
                            className="py-2 m-0 text-101 text-color5"
                          >
                            Total Percentage of all nominees
                          </Label>
                        </Col>
                        <Col lg={6}>
                          <Input
                            type="number"
                            className="nominee-form-input text-color1 text-4"
                            id="nameInput"
                            placeholder="Percentage"
                            disabled
                            //name="percentage_1"
                            value={values.Percentage}
                            name="Percentage"

                            invalid={
                              !isEmpty(errors) &&
                                errors.Percentage &&
                                errors.Percentage
                                ? true
                                : false
                            }
                          />
                          {!isEmpty(errors) &&
                            touched.Percentage &&
                            errors.Percentage ? (
                            <FormFeedback type="invalid">
                              {errors.Percentage}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    )}
                    {values.nominee.length <= 2 && (
                      <div className="form-group row">
                        <div className="col-md-5">
                          <button
                            type="button"
                            className="btn additional-page-button-save222 me-0 mt-2"
                            onClick={() => {
                              checkModificationStatus({
                                client_code: client_code,
                                pan_no: data2?.PANGIR,
                                field_name: "nominee",
                                esign_flag: "NO",
                              })
                                .then((response) => {
                                  //console.log(response);
                                  if (response.success === true) {
                                    toast.info(
                                      "Already we have one request for Nominee Add",
                                      {
                                        autoClose: 1500,
                                      }
                                    );
                                  } else {
                                    // setPercentageValue(100 / (values.nominee.length + 1));
                                    push(emptyNomineeRow);
                                    if (isEdited === false) {
                                      if (values.nominee.length == 0) {
                                        setFieldValue(
                                          `nominee[0].percentage_1`,
                                          100
                                        );
                                      } else if (values.nominee.length == 1) {
                                        setFieldValue(
                                          `nominee[0].percentage_1`,
                                          50
                                        );
                                        setFieldValue(
                                          `nominee[1].percentage_1`,
                                          50
                                        );
                                      }
                                      else if (values.nominee.length == 2) {
                                        setFieldValue(
                                          `nominee[0].percentage_1`,
                                          33
                                        );
                                        setFieldValue(
                                          `nominee[1].percentage_1`,
                                          33
                                        );
                                        setFieldValue(
                                          `nominee[2].percentage_1`,
                                          34
                                        );
                                      }
                                    }

                                  }
                                  //props.sendData(modalMobile);
                                })
                                .catch((err) => {
                                  ////console.log(err);
                                });
                            }}
                          >{
                              values.nominee.length == 0 ? 'Add Nominee' : 'Add More'
                            }

                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </FieldArray>
              {values.nominee.length > 0 && (
                <div className="d-flex justify-content-center">

                  {values.nominee.length > 0 && (
                    <div className="d-flex justify-content-center">
                      {!isEmpty(errors) ? (
                        <button
                          className="btn additional-page-button-save me-0 mt-4"
                          type="submit"
                          id="createBookingForm"
                        >
                          Save
                        </button>
                      ) : (
                        <NomineeDetailsModelIndex
                          type="submit"
                          id="createBookingForm"
                          data={nominee_Data}
                          profileData={data2}
                          state={props.state}
                          onStateChange={props.onStateChange}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NomineeDetailsAdd;
