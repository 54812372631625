import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import copy from 'copy-to-clipboard';
import {
  Input,
  Modal,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import {
  EmailIcon,
  WhatsappIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import {
  EmailShareButton,
  WhatsappShareButton,
  LineShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { selectedPlanApi } from "../../../../helpers/fakebackend_helper";
import { addToCart } from "../../../../store/cart/action";
// TODO Ui and design and functions.
const Share = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState({});
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const [info, setInfo] = useState([]);
  const { data } = props;
  const [copyText, setCopyText] = useState('');
  const [copy1, setCopy1] = useState(false);
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  // useEffect(() => {
  //   if (modal_signUpModals === true) {
  //     selectedPlanApi({ planID: data, code: client_code, token: client_token })
  //       .then((response) => {
  //         //console.log(response);
  //         setValue(response.data);
  //         setInfo(response.data.plan_durations[0])
  //         setIsLoading(false);
  //         ////console.log(response);
  //       })
  //       .catch((err) => {
  //         ////console.log(err);
  //       });
  //   }
  // }, [modal_signUpModals, client_token, client_code]);
  const [sellerId, setSellerID] = useState(0);
  // //console.log(sellerId);
  // //console.log(info);
  const tog_signUpModals = () => {
    setmodal_signUpModals(!modal_signUpModals);
  };

  const selectHandler = (seller, index) => {
    setSellerID(index);
    setInfo(seller);
  };

  const handleCopyText = (e) => {
    setCopyText(e.target.value);
}

const copyToClipboard = () => {
  //console.log("hello")
    copy(data);

    setCopy1(true);
    setTimeout(() => {
      setCopy1(false);
    }, 3000);
    //alert(`You have copied "${copyText}"`);
}

  const item = {
    client_code: client_code,
    client_token: client_token,
    feature_or_plan_id: info.subscription_plan_id,
    feature_or_plan: "Plan",
    duration_type: info.duration_type,
  };

  const addToCartHandler = () => {
    dispatch(addToCart(item));
    setmodal_signUpModals(!modal_signUpModals);
  };

  const myFunction = (data) => {
    navigator.clipboard.writeText(data);
    setCopy1(true);
    setTimeout(() => {
      setCopy1(false);
    }, 3000);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="mt-0 input-group-text text-5 ref-and-earn-button text-uppercase"
        onClick={() => tog_signUpModals()}
      >
        Copy & Share
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        className="share-model-middle"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
        style={{ width: "350px" }}
      >
        <ModalHeader
          className="px-4 py-4"
          toggle={() => {
            tog_signUpModals();
          }}
        >
          <h4
            className="text-2 text-color7 spacing-remove-bottom"
            style={{ margin: "auto 0" }}
          >
            Share the link
          </h4>
        </ModalHeader>
        {/* <div className="d-flex justify-content-between align-items-center px-3">
          <div className="d-flex align-items-center">
            <img src={value.image} style={{ width: "50px" }} />
            <div className="ms-2">
              <h4 className="text-2 text-color7 spacing-remove-bottom">
                {value.plan_name}
              </h4>
              <p className="m-0">Details</p>
            </div>
          </div>
          <div>
            <h4>Rs 1200 /-</h4>
            <p className="m-0">Monthly</p>
          </div>
        </div>
        <ModalBody className="px-3">
          <p>{value.description}</p>
          <h5>Feature Collection</h5>
          <div>
            <ul className="list-unstyled text-muted vstack gap-3">
              {!isEmpty(value) &&
                value.long_description.length > 0 &&
                value.long_description.map((subItem, index) => {
                  //console.log(subItem);
                  return (
                    <li key={index}>
                      <div className="d-flex">
                        {subItem.status === "Yes" ? (
                          <div className="flex-shrink-0 text-success me-2">
                            <i className="ri-checkbox-circle-line fs-15 align-middle"></i>
                          </div>
                        ) : subItem.status === "" ? (
                          <div className="flex-shrink-0 text-danger me-2 d-none">
                            <i className=" ri-close-circle-line fs-15 align-middle"></i>
                          </div>
                        ) : (
                          <div className="flex-shrink-0 text-danger me-2">
                            <i className=" ri-close-circle-line fs-15 align-middle"></i>
                          </div>
                        )}

                        {subItem.status === "" ? (
                          <div className="flex-grow-1 text-15 text-color4 d-none">
                            {subItem.title}
                          </div>
                        ) : (
                          <div className="flex-grow-1 text-15 text-color4">
                            {subItem.title}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <h5>Select Plan Duration</h5>
          <div>
            {!isEmpty(value) &&
              value.plan_durations.length > 0 &&
              value.plan_durations.map((seller, index) => {
                //console.log(seller);
                return (
                  <Card
                    key={index}
                    onClick={() => selectHandler(seller, index)}
                    className={`${
                      sellerId === index && "selected-box-border"
                    } ${index > 2 && "item-hide"} suggested-algos-col-card ${
                      sellerId !== index && "non-selected-box-border"
                    }`}
                  >
                    <CardBody
                      className="pt-3 pb-3 px-3"
                      style={{ height: "80px" }}
                    >
                      <div className="d-flex mb-1">
                        <div className="flex-grow-1">
                          <h5
                            className="mt-2 text-6 text-color1"
                            style={{ height: "25px" }}
                          >
                            {seller.duration_type}
                          </h5>
                        </div>
                        <div className="flex-shrink-0">
                          <h4>{seller.discounted_price}</h4>
                          <p>
                            {seller.duration === 30
                              ? "1 Month"
                              : seller.duration === 90
                              ? "3 Month"
                              : "12 Month"}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                );
              })}
          </div>
          <div className="d-flex text-center justify-content-center">
            <button
              className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
              style={{ borderRadius: "5px" }}
              onClick={() => {
                addToCartHandler(item);
              }}
            >
              Add to Cart
            </button>
          </div>
        </ModalBody> */}
        {/* <h4 className="text-center text-10 mb-3">Copy</h4> */}
        <div className="d-flex px-4 justify-content-center mb-2">
          <h4
            className="text-color12 background-color11 p-3 text-1 compleate-order-btn"
            style={{ cursor: "copy", border: "1px dashed #f0e8e8" }}
            onClick={() => {
              myFunction(data);
            }}
          >
            {!copy1 ? data : "Copied!"}
          </h4>
        </div>
        {/* <h4 className="text-center text-10  mb-3">Share with</h4> */}
        <div className="d-flex px-4 justify-content-center mb-4 align-items-center">
        <FacebookShareButton
            //url={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${data} for incredible rewards. Let's dive in and start trading with Bigul today!`}
            //quote="link"
            className="Demo__some-network__share-button me-2"
            url={data}
            quote={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${data} for incredible rewards. Let's dive in and start trading with Bigul today!`}
          >
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <WhatsappShareButton
            url={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${data} for incredible rewards. Let's dive in and start trading with Bigul today!`}
            quote="link"
            className="Demo__some-network__share-button me-2"
          >
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <EmailShareButton
            url={`
            Hi, How are you doing?
            You know, I've started using this amazing smart trading platform, Bigul, and it's truly a game changer. It has some really simple and customised features to trade for everything, from Algo Trading 
            to Options Trading to Mutual Funds.
            And, the best part is- if you join via my referral link ${data}, we both get fantastic rewards!
            I think it is a must-try.
            Let's embark on this trading journey together on Bigul.`}
            quote="link"
            className="Demo__some-network__share-button me-2"
          >
            <EmailIcon size={40} round bgStyle={{ fill: "#6464ff" }} />
          </EmailShareButton>
          <LinkedinShareButton
            url={data}
            quote="link"
            className="Demo__some-network__share-button me-2"
          >
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>

          <TwitterShareButton
            url={data}
            quote="link"
            className="Demo__some-network__share-button me-2"
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <button
            //className="input-group-text text-5 ref-and-earn-button text-uppercase"
            type="button"
            className="input-group-text"
            style={{
              border: "1px dashed #e7dddd",
              width: "40px",
              height: "40px",
              padding: "5px",
            }}
            // onClick={() => {
            //   myFunction(data);
            // }}
            onClick={copyToClipboard}
          >
            <i className="ri-file-copy-line text-color7 fs-4 mx-auto my-0"></i>
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Share;
