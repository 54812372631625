import React, { useEffect, useState } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import axios from "axios";
import { LOGOUT_APP } from "../helpers/url_helper";

const AuthProtected = (props) => {
  const [counter, setCounter] = useState(1);
  var client_code = localStorage.getItem('client_id');
  var client_token = localStorage.getItem('client_token');
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  function Encryptclcode(strUserId) {
    let ch = '';
    for (let i = 0; i < strUserId.length; i++) {
      let baSourceData = strUserId.charCodeAt(i);
      let a = baSourceData;
      let b = 111;
      let orComputed = a ^ b;
      ch += ToHexadecimal(orComputed);
    }
    return ch;
  }


  function ToHexadecimal(n) {
    if (n === 0) {
      return '';
    } else {
      let r = n % 16;
      n = Math.floor(n / 16);
      let result = ToHexadecimal(n);
      switch (r) {
        case 10:
          result += 'A';
          break;
        case 11:
          result += 'B';
          break;
        case 12:
          result += 'C';
          break;
        case 13:
          result += 'D';
          break;
        case 14:
          result += 'E';
          break;
        case 15:
          result += 'F';
          break;
        default:
          result += r;
          break;
      }
      return result;
    }
  }

  const search = useLocation().search;
  var client_code_new = new URLSearchParams(search).get('client_id');
  if (client_code_new == null) {

  } else {
    localStorage.clear();

    let cleanedQueryString = search.slice(1);
    var client_code_new = new URLSearchParams(search).get('client_id');
    var client_token_new = new URLSearchParams(search).get('token');
    const symphony_token = new URLSearchParams(search).get('symphony_token');
    const expire = new URLSearchParams(search).get('expires');
    const refreshToken = new URLSearchParams(search).get('refreshToken');
    const terminal_code = new URLSearchParams(search).get('terminal_code');
    if (client_code_new == undefined || client_code_new == 'NULL' || client_code_new == "" || client_token_new == undefined || client_token_new == 'NULL' || client_token_new == "") {
      var ii = 0;
    }
    else {
      let decodedStringAtoB = atob(client_code_new);
      let decodedStringAtoc = atob(client_token_new); // TODO seve to client_token
      let decodedStringAtod = atob(symphony_token); // TODO seve to client_token
      axios.get('https://client-dasboardapis.bigul.app/api/urldataenc?' + 'client_id=' + decodedStringAtoB + '&data=' + cleanedQueryString)
      // localStorage.removeItem('client_id');
      // localStorage.removeItem('token');
      // localStorage.removeItem('symphony_token');
      // localStorage.removeItem('encryptKey');
      // localStorage.removeItem('expire');
      // localStorage.removeItem('refreshToken');
      localStorage.setItem('client_id', decodedStringAtoB);
      localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
      localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
      localStorage.setItem('expires', expire); // TODO seve to client_token
      localStorage.setItem('refreshToken', refreshToken); // TODO seve to client_token
      localStorage.setItem('terminal_code', terminal_code); // TODO seve to client_token

      const encryptedClientId = Encryptclcode(decodedStringAtoB);

      localStorage.setItem('encryptKey', (JSON.stringify(encryptedClientId)));
      client_code = decodedStringAtoB;
      client_token = decodedStringAtoc;
    }
  }


  // if (client_code == undefined || client_code == 'NULL' || client_code == "" || client_token == undefined || client_token == 'NULL' || client_token == "") {
  //   var client_code_new = new URLSearchParams(search).get('client_id');
  //   var client_token_new = new URLSearchParams(search).get('token');
  //   const symphony_token = new URLSearchParams(search).get('symphony_token');
  //   const expire = new URLSearchParams(search).get('expires');
  //   const refreshToken = new URLSearchParams(search).get('refreshToken');
  //   const terminal_code = new URLSearchParams(search).get('terminal_code');
  //   if (client_code_new == undefined || client_code_new == 'NULL' || client_code_new == "" || client_token_new == undefined || client_token_new == 'NULL' || client_token_new == "") {
  //     var ii = 0;
  //   }
  //   else {
  //     let decodedStringAtoB = atob(client_code_new);
  //     let decodedStringAtoc = atob(client_token_new); // TODO seve to client_token
  //     let decodedStringAtod = atob(symphony_token); // TODO seve to client_token

  //     localStorage.removeItem('client_id');
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('symphony_token');
  //     localStorage.removeItem('encryptKey');
  //     localStorage.removeItem('expire');
  //     localStorage.removeItem('refreshToken');
  //     localStorage.setItem('client_id', decodedStringAtoB);
  //     localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //     localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
  //     localStorage.setItem('expire', expire); // TODO seve to client_token
  //     localStorage.setItem('refreshToken', refreshToken); // TODO seve to client_token
  //     localStorage.setItem('terminal_code', terminal_code); // TODO seve to client_token

  //     const encryptedClientId = Encryptclcode(decodedStringAtoB);

  //     localStorage.setItem('encryptKey', (JSON.stringify(encryptedClientId)));
  //     client_code = decodedStringAtoB;
  //     client_token = decodedStringAtoc;
  //   }
  // } else {
  //   var client_code_new = new URLSearchParams(search).get('client_id');
  //   var client_token_new = new URLSearchParams(search).get('token');
  //   const symphony_token = new URLSearchParams(search).get('symphony_token');
  //   const expire = new URLSearchParams(search).get('expires');
  //   const refreshToken = new URLSearchParams(search).get('refreshToken');
  //   const terminal_code = new URLSearchParams(search).get('terminal_code');



  //   if (client_code_new == undefined || client_code_new == 'NULL' || client_code_new == "" || client_token_new == undefined || client_token_new == 'NULL' || client_token_new == "") {
  //     var ii = 0;
  //   }
  //   else {
  //     let decodedStringAtoB = atob(client_code_new);
  //     let decodedStringAtoc = atob(client_token_new); // TODO seve to client_token
  //     let decodedStringAtod = atob(symphony_token); // TODO seve to client_token
  //     // let decodedStringexpire = atob(expire); // TODO seve to client_token

  //     if (decodedStringAtoB !== client_code) {
  //       localStorage.removeItem('client_id');
  //       localStorage.removeItem('token');
  //       localStorage.removeItem('symphony_token');
  //       localStorage.removeItem('encryptKey');
  //       localStorage.removeItem('expire');
  //       localStorage.removeItem('refreshToken');
  //       localStorage.removeItem('terminal_code');
  //       localStorage.setItem('client_id', decodedStringAtoB);
  //       localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //       localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
  //       const encryptedClientId = Encryptclcode(decodedStringAtoB);

  //       localStorage.setItem('encryptKey', (JSON.stringify(encryptedClientId)));
  //       localStorage.setItem('expire', expire); // TODO seve to client_token
  //       localStorage.setItem('refreshToken', refreshToken); // TODO seve to client_token
  //       localStorage.setItem('terminal_code', terminal_code); // TODO seve to client_token

  //       client_code = decodedStringAtoB;
  //       client_token = decodedStringAtoc;
  //     }
  //   }
  // }



  useEffect(() => {
    counter > 0 &&
      setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setTimeout(function () {
        if (profileDetails.length > 0) {
          var get_user_datat = profileDetails[0].OOWNCODE;
          if (client_code != get_user_datat) {
            localStorage.setItem('client_id', get_user_datat);
          }
        }
      }, 1500);
      if (client_token === null || client_token === "" || client_token === undefined || client_code === null || client_code === "" || client_code === undefined) {
        return window.open(LOGOUT_APP, "_self");
      }
    }
  }, [counter]);
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };

