// // import React, { useEffect, useState } from "react";
// // import { useSelector } from "react-redux";
// // import {
// //   pennyDrop,
// //   checkModificationStatus,
// //   getBankMasterInfo,
// // } from "../../../helpers/fakebackend_helper";
// // import ShowBankDetails from "./ShowBankDetails";
// // import { Col, Row, Label, Input } from "reactstrap";
// // import { toast } from "react-toastify";
// // import axios from "axios";

// // const BankDetails = (props) => {

// //   const { data, activeTab } = props;
// //   console.log(data[0],'props'); 
// //   const client_code = localStorage.getItem("client_id");
// //   const [addBank1, setAddBank1] = useState(false);
// //   const [addBank2, setAddBank2] = useState(false);
// //   const [ifsc, setIfsc] = useState('');
// //   const [bankN, setBankN] = useState('');
// //   const [bankC, setBankC] = useState('');
// //   const [bankDetails, setBankDetails] = useState({});
// //   const [statusCheck, setCheckstatue] = useState({});
// //   const [proofType, setProofType] = useState('');
// //   const [file, setFile] = useState(null);

// //   useEffect(() => {
// //     setCheckstatue({});
// //     setIfsc('');
// //     setBankN('');
// //     setBankC('');
// //     setBankDetails({});
// //     setAddBank1(false);
// //     if (activeTab === "5") {
// //       apicall()
// //     }
// //   }, [activeTab, addBank2]);

// //   const apicall=()=>{
// //     checkModificationStatus({
// //       client_code: client_code,
// //       entry_type: "bank",
// //     })
// //       .then((response) => {
// //         if (response.status === true && response?.data?.modification?.length > 0) {
// //           // setAddBank1(true);
// //           setAddBank2(true)
// //         } else {
// //           setAddBank2(false) // setAddBank1(false);

// //         }
// //       })
// //       .catch((err) => {
// //         // Handle error
// //       });
// //   }

// //   const addBank = () => {
// //     setAddBank1(true);
// //     setIfsc('');
// //     setBankDetails({});
// //     setCheckstatue({});
// //     setBankN('');
// //     setBankC('');
// //   };

// //   const handleBank = () => {

// //     if (ifsc.length === 0) {
// //       toast('Please enter IFSC code');
// //       return;
// //     }
// //     if (bankN.length === 0) {
// //       toast('Please enter account number');
// //       return;
// //     }
// //     if (bankC.length === 0) {
// //       toast('Please enter confirm account number');
// //       return;
// //     }
// //     if (bankC !== bankN) {
// //       toast('Your bank account number and confirm account number do not match');
// //       return;
// //     } else {
// //       pennyDrop({
// //         beneficiary_account_no: bankC,
// //         beneficiary_ifsc: ifsc,
// //       })
// //         .then((response) => {
// //           if (response?.verified ) {
// //             setCheckstatue(response);
// //             const formData = new FormData();
// //             formData.append('client_code', client_code);
// //             formData.append('entry_type', 'bank');
// //             formData.append('new[bank_branch]', bankDetails.branch_name);
// //             formData.append('new[bank_account_number]', bankN);
// //             formData.append('new[bank_name]', bankDetails.bank_name);
// //             formData.append('new[ifsc_code]', ifsc);
// //             formData.append('new[micr_number]', bankDetails.micr_code);
// //             formData.append('old[bank_branch]', 'AA');
// //             formData.append('old[bank_account_number]',data[0]?.ACNUMBER);
// //             formData.append('old[bank_name]',data[0]?.BANK_NAME);
// //             formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
// //             formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
// //             formData.append('doc_json', JSON.stringify(response));
// //             formData.append('modify_key[]', 'bank_branch');
// //             formData.append('modify_key[]', 'bank_account_number');
// //             formData.append('modify_key[]', 'bank_name');
// //             formData.append('modify_key[]', 'ifsc_code');
// //             formData.append('modify_key[]', 'micr_number');
// //             formData.append('doc_type', 'PD');
// //             formData.append('doc_id', response?.id);

// //             axios.post('https://newkyc.bigul.app/modification/update-modification', formData, {
// //               headers: {
// //                 'Content-Type': 'multipart/form-data',
// //                 'Authorization': 'Bearer your_token', 
// //               },
// //             })

// //               .then(response => {
// //                 if (response.status == false || response.message == 'You have already requested for bank updation.') {
// //                   setAddBank1(false)
// //                   apicall()

// //                   return
// //                 }
// //                 if (response.status) {

// //                   setAddBank1(false)
// //                   apicall()
// //                   return
// //                 }
// //                 else {
// //                   toast(response.message)
// //                 }

// //               })
// //               .catch(error => {

// //               });
// //             // setAddBank1(false);

// //           }
// //           else {
// //             setCheckstatue(response);

// //           }

// //         })
// //         .catch((err) => {
// //           // Handle error
// //         });
// //     }
// //   };

// //   return (
// //     <>
// //       {addBank1 === false ? (
// //         <ShowBankDetails dd1={data} addBank={addBank} addBank2={addBank2} />
// //       ) : (
// //         <>
// //           <button
// //             type="button"
// //             className="additional-page-button-cancel me-2"
// //             onClick={() => {
// //               setAddBank1(false);
// //             }}
// //           >
// //             Go Back
// //           </button>

// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="ifscInput" className="py-2 m-0 text-101 text-color5">
// //                 IFSC Code
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
// //                 id="ifscInput"
// //                 name="ifsc_code"
// //                 type="text"
// //                 value={ifsc}
// //                 onChange={(e) => setIfsc(e.target.value)}
// //               />
// //             </Col>
// //             <Col lg={3} className="d-flex align-items-center">
// //               <button
// //                 type="button"
// //                 className="text-101 text-color7 profile-edit-button2"
// //                 onClick={() => {
// //                   if (ifsc.length === 0) {
// //                     toast('Please enter IFSC code');
// //                   } else {
// //                     getBankMasterInfo({ BankVal: ifsc })
// //                       .then((response) => {
// //                         if (response.success === true) {
// //                           setBankDetails(response.data[0]);
// //                         }
// //                       })
// //                       .catch((err) => {
// //                         // Handle error
// //                       });
// //                   }
// //                 }}
// //               >
// //                 Fetch
// //               </button>
// //             </Col>
// //           </Row>

// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="bankNameInput" className="py-2 m-0 text-101 text-color5">
// //                 Bank Name
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
// //                 id="bankNameInput"
// //                 name="bank_name"
// //                 type="text"
// //                 disabled
// //                 value={bankDetails?.bank_name || ''}
// //               />
// //             </Col>
// //           </Row>
// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="branchNameInput" className="py-2 m-0 text-101 text-color5">
// //                 Branch Name
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
// //                 id="branchNameInput"
// //                 name="branch_name"
// //                 type="text"
// //                 disabled
// //                 value={bankDetails?.branch_name || ''}
// //               />
// //             </Col>
// //           </Row>
// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="micrInput" className="py-2 m-0 text-101 text-color5">
// //                 MICR Number
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
// //                 id="micrInput"
// //                 name="micr_code"
// //                 type="text"
// //                 disabled
// //                 value={bankDetails?.micr_code || ''}
// //               />
// //             </Col>
// //           </Row>
// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="accountNumberInput" className="py-2 m-0 text-101 text-color5">
// //                 Bank Account Number
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4"
// //                 id="accountNumberInput"
// //                 placeholder="**** **** 0000"
// //                 name="bank_account_number"
// //                 value={bankN}
// //                 onChange={(e) => setBankN(e.target.value)}
// //               />
// //             </Col>
// //           </Row>
// //           <Row className="mb-3">
// //             <Col lg={3}>
// //               <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
// //                 Confirm Bank Account Number
// //               </Label>
// //             </Col>
// //             <Col lg={6}>
// //               <Input
// //                 className="nominee-form-input text-color1 text-4"
// //                 id="confirmAccountNumberInput"
// //                 placeholder="**** **** 0000"
// //                 name="confirm_bank_account_number"
// //                 value={bankC}
// //                 onChange={(e) => setBankC(e.target.value)}
// //               />
// //             </Col>
// //           </Row>

// //           {statusCheck?.verified === false && (
// //             <>
// //               <Row className="mb-3">
// //                 <Col lg={3}>
// //                   <Label htmlFor="proofTypeSelect" className="py-2 m-0 text-101 text-color5">
// //                     Select Proof
// //                   </Label>
// //                 </Col>
// //                 <Col lg={6}>
// //                   <Input
// //                     className="nominee-form-input text-color1 text-4 nominee-form-input-select"
// //                     id="proofTypeSelect"
// //                     name="bank_proof"
// //                     type="select"
// //                     value={proofType}
// //                     onChange={(e) => setProofType(e.target.value)}
// //                   >
// //                     <option key="0" value="" disabled>
// //                       Select Type
// //                     </option>
// //                     <option key="1" value="Bank Statement">
// //                       Bank Statement
// //                     </option>
// //                     <option key="2" value="Personalized Cheque">
// //                       Personalized Cheque
// //                     </option>
// //                     <option key="3" value="Bank Passbook">
// //                       Bank Passbook
// //                     </option>
// //                     <option key="4" value="Bank Letter">
// //                       Bank Letter
// //                     </option>
// //                   </Input>
// //                 </Col>
// //               </Row>
// //               <Row className="mb-3">
// //                 <Col lg={3}>
// //                   <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
// //                     Upload Proof File
// //                   </Label>
// //                 </Col>
// //                 <Col lg={6}>
// //                   <Input
// //                     className="nominee-form-input text-color1 text-4"
// //                     type="file"
// //                     id="fileInput"
// //                     placeholder="No File Selected"
// //                     name="file"
// //                     onChange={(e) => setFile(e.target.files[0])}
// //                   />
// //                 </Col>
// //               </Row>
// //             </>
// //           )}
// //           {

// //           }
// // <button
// //             className="btn me-3 additional-page-button-save mt-2"
// //             type="submit"
// //             onClick={handleBank}
// //           >
// //             Authenticate
// //           </button>

// //         </>
// //       )}
// //     </>
// //   );
// // };

// // export default BankDetails;


// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   pennyDrop,
//   checkModificationStatus,
//   getBankMasterInfo,
// } from "../../../helpers/fakebackend_helper";
// import ShowBankDetails from "./ShowBankDetails";
// import { Col, Row, Label, Input } from "reactstrap";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { MOD_EKYC_NEW } from "../../../helpers/url_helper";

// const BankDetails = (props) => {

//   const { data, activeTab } = props;

//   const client_code = localStorage.getItem("client_id");
//   const client_token = localStorage.getItem("client_token");
//   const [addBank1, setAddBank1] = useState(false);
//   const [addBank2, setAddBank2] = useState(false);
//   const [ifsc, setIfsc] = useState('');
//   const [bankN, setBankN] = useState('');
//   const [doc_password, SetdocPassword] = useState('');
//   const [bankC, setBankC] = useState('');
//   const [bankDetails, setBankDetails] = useState({});
//   const [statusCheck, setCheckstatue] = useState({});
//   const [fullBank, setFullbank] = useState([]);
//   const [fullBankPrimary, setFullbankPrimary] = useState([]);
//   const [proofType, setProofType] = useState('');
//   const [file, setFile] = useState(null);
//   const [state, setState] = useState(0);
//   const [shouldFetch, setShouldFetch] = useState(false);


//   useEffect(() => {

//     let intervalId;

//     const callApi = () => {
//       checkModificationStatus({
//         client_code: client_code,
//         entry_type: 'primary_bank',

//       })

//         .then((response) => {
//           if (response.status == true && response?.data.modification.length > 0) {
//             setFullbankPrimary(response?.data.modification)

//             if (response?.data.modification[0]?.is_esign == 1) {
//               setShouldFetch(false)
//             }

//           }
//           else {
//             setFullbankPrimary([])

//           }

//         })
//         .catch((err) => {
//           toast('something went wrong')

//           // setShouldContinueApi(false)
//         });


//     };

//     if (props.activeTab === "5" && shouldFetch) {
//       intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
//     }

//     return () => {
//       clearInterval(intervalId); // Cleanup on unmount or condition change
//     };
//   }, [props.activeTab === "5", shouldFetch]);

//   useEffect(() => {
//     setCheckstatue({});
//     setIfsc('');
//     setBankN('');
//     SetdocPassword('');
//     setBankC('');
//     setBankDetails([]);
//     setAddBank1(false);
//     setFile(null)
//     setProofType('')
//     if (activeTab === "5") {
//       apicall()
//     }
//   }, [activeTab, addBank2]);

//   useEffect(() => {
//     setShouldFetch(false)
//   }, [props.activeTab === "5"])

//   useEffect(() => {
//     setState(0)

//     checkModificationStatus({
//       client_code: client_code,
//       entry_type: "primary_bank",
//     })
//       .then((response) => {
//         if (response.status == true && response?.data.modification.length > 0) {
//           setFullbankPrimary(response?.data.modification)
//           if (response?.data.rejection.length) {
//             setFullbankPrimary([])
//           }

//         }
//         else {
//           setFullbankPrimary([])

//         }

//       })
//       .catch((err) => {
//         // Handle error
//       });
//   }, [props.activeTab === "5", addBank2, state])

//   const apicall = () => {
//     checkModificationStatus({
//       client_code: client_code,
//       entry_type: "bank",
//     })
//       .then((response) => {
//         if (response.status === true && response?.data?.modification?.length > 0) {
//           setFullbank(response?.data?.modification)
//           setAddBank2(true)
//         } else {
//           setAddBank2(false)
//           setFullbank([])
//           // setAddBank1(false);

//         }
//       })
//       .catch((err) => {
//         // Handle error
//       });
//   }

//   const addBank = () => {
//     setAddBank1(true);
//     setIfsc('');
//     setBankDetails([]);
//     setCheckstatue({});
//     setBankN('');
//     SetdocPassword('');
//     setBankC('');
//   };

//   const handleBank = () => {
//     setProofType('')
//     setFile(null)
//     if (ifsc.length === 0) {
//       toast('Please enter IFSC code');
//       return;
//     }
//     if (bankN.length === 0) {
//       toast('Please enter account number');
//       return;
//     }
//     if (bankC.length === 0) {
//       toast('Please enter confirm account number');
//       return;
//     }


//     if (bankC !== bankN) {
//       toast('Your bank account number and confirm account number do not match');
//       return;
//     }
//     if (bankDetails.length === 0) {
//       toast('your bank name and branch name not fetch ');
//       return;
//     }
//     if (bankN.length < 8) {
//       toast('please enter valid Account number');
//       return;
//     }
//     else {
//       pennyDrop({
//         beneficiary_account_no: bankC,
//         beneficiary_ifsc: ifsc,
//       })
//         .then((response) => {
//           if (response?.verified) {
//             setCheckstatue(response);
//             const formData = new FormData();
//             formData.append('client_code', client_code);
//             formData.append('client_token', client_token);
//             formData.append('entry_type', 'bank');
//             formData.append('new[bank_branch]', bankDetails.branch_name);
//             formData.append('new[bank_account_number]', bankN);
//             formData.append('new[bank_name]', bankDetails.bank_name);
//             formData.append('new[ifsc_code]', ifsc);
//             formData.append('new[micr_number]', bankDetails.micr_code);
//             formData.append('old[bank_branch]', 'AA');
//             formData.append('old[bank_account_number]', data[0]?.ACNUMBER);
//             formData.append('old[bank_name]', data[0]?.BANK_NAME);
//             formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
//             formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
//             formData.append('doc_json', JSON.stringify(response));
//             formData.append('modify_key[]', 'bank_branch');
//             formData.append('modify_key[]', 'bank_account_number');
//             formData.append('modify_key[]', 'bank_name');
//             formData.append('modify_key[]', 'ifsc_code');
//             formData.append('modify_key[]', 'micr_number');
//             formData.append('doc_type', 'PD');
//             formData.append('doc_id', response?.id);

//             axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
//               headers: {
//                 'Content-Type': 'multipart/form-data',
//                 'Authorization': 'Bearer your_token',
//               },
//             })

//               .then(response => {
//                 if (response.status == false || response.message == 'You have already requested for bank updation.') {
//                   setAddBank1(false)
//                   apicall()

//                   return
//                 }
//                 if (response.status) {
//                   setAddBank1(false)
//                   apicall()
//                   return
//                 }
//                 else {
//                   toast(response.message)
//                 }

//               })
//               .catch(error => {

//               });
//             // setAddBank1(false);

//           }
//           else {
//             if (response?.verified == false) {
//               setCheckstatue(response);
//             }
//             else {
//               toast.error(response?.message)
//             }


//           }

//         })
//         .catch((err) => {
//           // Handle error
//         });
//     }
//   };
//   const handleBank2 = () => {

//     const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
//     const maxFileSize = 2 * 1024 * 1024;
//     if (proofType == '') {
//       toast('Please select  bank proof type');
//       return;
//     }
//     if (file == null) {
//       toast('Please select bank proof');
//       return;
//     }
//     if (!allowedFileTypes.includes(file.type)) {
//       toast('Invalid file type. Only PDF, PNG, and JPEG are allowed.');
//       return;
//     }

//     if (file.size > maxFileSize) {
//       toast('File size exceeds 2 MB.');
//       return;
//     }
//     else {
//       const formData = new FormData();
//       formData.append('client_code', client_code);
//       formData.append('client_token', client_token);
//       formData.append('entry_type', 'bank');
//       formData.append('new[bank_branch]', bankDetails.branch_name);
//       formData.append('new[bank_account_number]', bankN);
//       formData.append('new[bank_name]', bankDetails.bank_name);
//       formData.append('new[ifsc_code]', ifsc);
//       formData.append('new[micr_number]', bankDetails.micr_code);
//       formData.append('old[bank_branch]', 'AA');
//       formData.append('old[bank_account_number]', data[0]?.ACNUMBER);
//       formData.append('old[bank_name]', data[0]?.BANK_NAME);
//       formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
//       formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
//       // formData.append('doc_json',statusCheck);
//       formData.append('modify_key[]', 'bank_branch');
//       formData.append('modify_key[]', 'bank_account_number');
//       formData.append('modify_key[]', 'bank_name');
//       formData.append('modify_key[]', 'ifsc_code');
//       formData.append('modify_key[]', 'micr_number');
//       formData.append('doc_type', 'Bank_Proof');
//       formData.append('doc_password', doc_password);
//       formData.append('doc_id', statusCheck?.id);
//       formData.append('proof_type', proofType);
//       formData.append('doc_path', file);


//       axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'Authorization': 'Bearer your_token',
//         },
//       })

//         .then(response => {
//           if (response.status == false || response.message == 'You have already requested for bank updation.') {
//             setAddBank1(false)
//             apicall()

//             return
//           }
//           if (response.status) {

//             setAddBank1(false)
//             apicall()
//             return
//           }
//           else {
//             toast(response.message)
//           }

//         })
//         .catch(error => {

//         });
//     }
//   };


//   const Addbankname = fullBank?.filter(item => item?.modify_key === "bank_name");
//   const Addbankname1 = fullBank?.filter(item => item?.modify_key === "bank_account_number");
//   const Addbankname2 = fullBank?.filter(item => item?.modify_key === "ifsc_code");

//   const handleStateChange = (newState) => {
//     setState(newState);
//   };

//   const handleStateChange1 = (value) => {
//     setShouldFetch(value);
//   };
//   const handlePaste = (e) => {
//     e.preventDefault();
//     // toast.error('Pasting is not allowed!');
//   };

//   return (
//     <>
//       {addBank1 === false ? (
//         <>
//           <ShowBankDetails dd1={data} addBank={addBank} addBank2={addBank2} fullBankPrimary={fullBankPrimary} state={state} onStateChange={handleStateChange} apicheck={handleStateChange1} />
//           {
//             fullBank.length > 0 &&
//             <div>
//               {/* <h4 className="text-16 text-color1 mt-3">Primary Account</h4> */}
//               {
//                 <Row className="nominee-details mt-3 mx-0 py-3">
//                   {/* <Col lg={1} xs={3}>
//                 <div className="bank-details-image-div me-4">
//                   <img src={hdfc} className="bank-details-image" />
//                 </div>
//               </Col> */}
//                   <Col lg={3} xs={6}>
//                     {/* <img src={hdfc} className="bank-details-image" /> */}
//                     <h2 className="text-101 text-color7">{Addbankname[0]?.new_value}</h2>
//                     <div className="d-flex">
//                       <h4 className="text-11 text-color9 me-2 bank-details-badge2 p-1 mb-2">
//                         Other{" "}
//                       </h4>
//                       <h4 className="text-11 text-color9">
//                         {/* <i className="ri-edit-line fs-5 text-color5" /> */}
//                         {/* <EditChages /> */}
//                       </h4>
//                     </div>
//                   </Col>
//                   <Col lg={3}>
//                     <h2 className="text-11 text-color9 mb-2">Bank Account Number</h2>
//                     <h4 className="text-4 text-color1">{Addbankname1[0]?.new_value}</h4>
//                   </Col>

//                   <Col lg={3}>
//                     <h2 className="text-11 text-color9 mb-2">IFSC Code</h2>
//                     <h4 className="text-4 text-color1">{Addbankname2[0]?.new_value.toUpperCase()}</h4>
//                   </Col>
//                   <Col lg={3}>
//                     <h2 className="text-11 text-color9 mb-2">Status</h2>
//                     <h4 className="text-4 text-color10">{Addbankname[0]?.is_esign == 0 ? 'Pending' : Addbankname[0]?.is_send_to_ld == 1 ? 'In progress' : null}</h4>
//                   </Col>

//                 </Row>}
//             </div>
//           }

//         </>
//       ) : (
//         <>
//           <button
//             type="button"
//             className="additional-page-button-cancel me-2"
//             onClick={() => {
//               setAddBank1(false);
//             }}
//           >
//             Go Back
//           </button>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="ifscInput" className="py-2 m-0 text-101 text-color5">
//                 IFSC Code
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="ifscInput"
//                 name="ifsc_code"
//                 type="text"
//                 value={ifsc}
//                 maxLength={11}
//                 onChange={(e) => setIfsc(e.target.value)}
//               />
//             </Col>
//             <Col lg={3} className="d-flex align-items-center">
//               <button
//                 type="button"
//                 className="btn btn-primary me-3 additional-page-button-save"
//                 onClick={() => {
//                   if (ifsc.length === 0) {
//                     toast.error('Please enter IFSC code');
//                     return
//                   }
//                   if (ifsc.length < 9) {
//                     toast.error('Please enter valid IFSC code');
//                     return
//                   }
//                   else {
//                     getBankMasterInfo({ BankVal: ifsc })
//                       .then((response) => {
//                         if (response.success === true) {
//                           if (response.data[0]?.bank_name.length > 2) {
//                             setBankDetails(response.data[0]);
//                           } else {
//                             toast.error('Invalid IFSC code')
//                           }
//                         }
//                       })
//                       .catch((err) => {
//                        toast(err?.message || 'Something went wrong')
//                       });
//                   }
//                 }}
//               >
//                 Fetch
//               </button>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="bankNameInput" className="py-2 m-0 text-101 text-color5">
//                 Bank Name
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="bankNameInput"
//                 name="bank_name"
//                 type="text"
//                 disabled
//                 value={bankDetails?.bank_name || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3 d-none">
//             <Col lg={3}>
//               <Label htmlFor="branchNameInput" className="py-2 m-0 text-101 text-color5">
//                 Branch Name
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="branchNameInput"
//                 name="branch_name"
//                 type="text"
//                 disabled
//                 value={bankDetails?.branch_name || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="micrInput" className="py-2 m-0 text-101 text-color5">
//                 MICR Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="micrInput"
//                 name="micr_code"
//                 type="text"
//                 disabled
//                 value={bankDetails?.micr_code || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="accountNumberInput" className="py-2 m-0 text-101 text-color5">
//                 Bank Account Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4"
//                 id="accountNumberInput"
//                 placeholder="**** **** 0000"
//                 name="bank_account_number"
//                 value={bankN}
//                 onPaste={handlePaste}
//                 onChange={(e) => setBankN(e.target.value)}
//                 maxLength={18}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
//                 Confirm Bank Account Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4"
//                 id="confirmAccountNumberInput"
//                 placeholder="**** **** 0000"
//                 name="confirm_bank_account_number"
//                 value={bankC}
//                 onPaste={handlePaste}
//                 onChange={(e) => setBankC(e.target.value)}
//                 maxLength={18}
//               />
//             </Col>
//           </Row>

//           {statusCheck?.verified === false && (
//             <>
//               <Row className="mb-3">
//                 <Col lg={3}>
//                   <Label htmlFor="proofTypeSelect" className="py-2 m-0 text-101 text-color5">
//                     Select Proof
//                   </Label>
//                 </Col>
//                 <Col lg={6}>
//                   <Input
//                     className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                     id="proofTypeSelect"
//                     name="bank_proof"
//                     type="select"
//                     value={proofType}
//                     onChange={(e) => setProofType(e.target.value)}
//                   >
//                     <option key="0" value="" disabled>
//                       Select Type
//                     </option>
//                     <option key="1" value="Bank Statement">
//                       Bank Statement
//                     </option>
//                     <option key="2" value="Personalized Cheque">
//                       Personalized Cheque
//                     </option>
//                     <option key="3" value="Bank Passbook">
//                       Bank Passbook
//                     </option>
//                     <option key="4" value="Bank Letter">
//                       Bank Letter
//                     </option>
//                   </Input>
//                 </Col>
//               </Row>
//               <Row className="mb-3">
//                 <Col lg={3}>
//                   <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
//                     Upload Proof File
//                   </Label>
//                 </Col>
//                 <Col lg={6}>
//                   <Input
//                     className="nominee-form-input text-color1 text-4"
//                     type="file"
//                     id="fileInput"
//                     placeholder="No File Selected"
//                     name="file"
//                     onChange={(e) => setFile(e.target.files[0])}
//                   />
//                 </Col>

//               </Row>

//               <Row className="mb-3">
//                 <Col lg={3}>
//                   <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
//                    Password
//                   </Label>
//                 </Col>

//                 <Col lg={6}>
//                 <Input
//                     type="text"
//                     className="nominee-form-input text-color1 text-4 mt-3"
//                     id="nameInput"
//                     placeholder="Enter File Password,if any"
//                     name="file_Password"
//                     maxLength={20}
//                     onChange={(e) =>  SetdocPassword(e.target.value)}
//                     // value={validation.values.file_Password}
//                     // onBlur={validation.handleBlur}
//                     // onChange={validation.handleChange}
//                     // invalid={
//                     //   validation.errors.file_Password &&
//                     //     validation.touched.file_Password
//                     //     ? true
//                     //     : false
//                     // }
//                   />
//                 </Col>

//               </Row>
//             </>
//           )}
//           {
//             statusCheck && Object.keys(statusCheck).length === 0 ?
//               <button
//                 className="btn me-3 additional-page-button-save mt-2"
//                 type="submit"
//                 onClick={handleBank}
//               >
//                 Authenticate
//               </button>
//               :
//               <button
//                 className="btn me-3 additional-page-button-save mt-2"
//                 type="submit"
//                 onClick={handleBank2}
//               >
//                 Save
//               </button>
//           }


//         </>
//       )}
//     </>
//   );
// };

// export default BankDetails;

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   pennyDrop,
//   checkModificationStatus,
//   getBankMasterInfo,
// } from "../../../helpers/fakebackend_helper";
// import ShowBankDetails from "./ShowBankDetails";
// import { Col, Row, Label, Input } from "reactstrap";
// import { toast } from "react-toastify";
// import axios from "axios";

// const BankDetails = (props) => {

//   const { data, activeTab } = props;
//   console.log(data[0],'props'); 
//   const client_code = localStorage.getItem("client_id");
//   const [addBank1, setAddBank1] = useState(false);
//   const [addBank2, setAddBank2] = useState(false);
//   const [ifsc, setIfsc] = useState('');
//   const [bankN, setBankN] = useState('');
//   const [bankC, setBankC] = useState('');
//   const [bankDetails, setBankDetails] = useState({});
//   const [statusCheck, setCheckstatue] = useState({});
//   const [proofType, setProofType] = useState('');
//   const [file, setFile] = useState(null);

//   useEffect(() => {
//     setCheckstatue({});
//     setIfsc('');
//     setBankN('');
//     setBankC('');
//     setBankDetails({});
//     setAddBank1(false);
//     if (activeTab === "5") {
//       apicall()
//     }
//   }, [activeTab, addBank2]);

//   const apicall=()=>{
//     checkModificationStatus({
//       client_code: client_code,
//       entry_type: "bank",
//     })
//       .then((response) => {
//         if (response.status === true && response?.data?.modification?.length > 0) {
//           // setAddBank1(true);
//           setAddBank2(true)
//         } else {
//           setAddBank2(false) // setAddBank1(false);

//         }
//       })
//       .catch((err) => {
//         // Handle error
//       });
//   }

//   const addBank = () => {
//     setAddBank1(true);
//     setIfsc('');
//     setBankDetails({});
//     setCheckstatue({});
//     setBankN('');
//     setBankC('');
//   };

//   const handleBank = () => {

//     if (ifsc.length === 0) {
//       toast('Please enter IFSC code');
//       return;
//     }
//     if (bankN.length === 0) {
//       toast('Please enter account number');
//       return;
//     }
//     if (bankC.length === 0) {
//       toast('Please enter confirm account number');
//       return;
//     }
//     if (bankC !== bankN) {
//       toast('Your bank account number and confirm account number do not match');
//       return;
//     } else {
//       pennyDrop({
//         beneficiary_account_no: bankC,
//         beneficiary_ifsc: ifsc,
//       })
//         .then((response) => {
//           if (response?.verified ) {
//             setCheckstatue(response);
//             const formData = new FormData();
//             formData.append('client_code', client_code);
//             formData.append('entry_type', 'bank');
//             formData.append('new[bank_branch]', bankDetails.branch_name);
//             formData.append('new[bank_account_number]', bankN);
//             formData.append('new[bank_name]', bankDetails.bank_name);
//             formData.append('new[ifsc_code]', ifsc);
//             formData.append('new[micr_number]', bankDetails.micr_code);
//             formData.append('old[bank_branch]', 'AA');
//             formData.append('old[bank_account_number]',data[0]?.ACNUMBER);
//             formData.append('old[bank_name]',data[0]?.BANK_NAME);
//             formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
//             formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
//             formData.append('doc_json', JSON.stringify(response));
//             formData.append('modify_key[]', 'bank_branch');
//             formData.append('modify_key[]', 'bank_account_number');
//             formData.append('modify_key[]', 'bank_name');
//             formData.append('modify_key[]', 'ifsc_code');
//             formData.append('modify_key[]', 'micr_number');
//             formData.append('doc_type', 'PD');
//             formData.append('doc_id', response?.id);

//             axios.post('https://newkyc.bigul.app/modification/update-modification', formData, {
//               headers: {
//                 'Content-Type': 'multipart/form-data',
//                 'Authorization': 'Bearer your_token', 
//               },
//             })

//               .then(response => {
//                 if (response.status == false || response.message == 'You have already requested for bank updation.') {
//                   setAddBank1(false)
//                   apicall()

//                   return
//                 }
//                 if (response.status) {

//                   setAddBank1(false)
//                   apicall()
//                   return
//                 }
//                 else {
//                   toast(response.message)
//                 }

//               })
//               .catch(error => {

//               });
//             // setAddBank1(false);

//           }
//           else {
//             setCheckstatue(response);

//           }

//         })
//         .catch((err) => {
//           // Handle error
//         });
//     }
//   };

//   return (
//     <>
//       {addBank1 === false ? (
//         <ShowBankDetails dd1={data} addBank={addBank} addBank2={addBank2} />
//       ) : (
//         <>
//           <button
//             type="button"
//             className="additional-page-button-cancel me-2"
//             onClick={() => {
//               setAddBank1(false);
//             }}
//           >
//             Go Back
//           </button>

//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="ifscInput" className="py-2 m-0 text-101 text-color5">
//                 IFSC Code
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="ifscInput"
//                 name="ifsc_code"
//                 type="text"
//                 value={ifsc}
//                 onChange={(e) => setIfsc(e.target.value)}
//               />
//             </Col>
//             <Col lg={3} className="d-flex align-items-center">
//               <button
//                 type="button"
//                 className="text-101 text-color7 profile-edit-button2"
//                 onClick={() => {
//                   if (ifsc.length === 0) {
//                     toast('Please enter IFSC code');
//                   } else {
//                     getBankMasterInfo({ BankVal: ifsc })
//                       .then((response) => {
//                         if (response.success === true) {
//                           setBankDetails(response.data[0]);
//                         }
//                       })
//                       .catch((err) => {
//                         // Handle error
//                       });
//                   }
//                 }}
//               >
//                 Fetch
//               </button>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="bankNameInput" className="py-2 m-0 text-101 text-color5">
//                 Bank Name
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="bankNameInput"
//                 name="bank_name"
//                 type="text"
//                 disabled
//                 value={bankDetails?.bank_name || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="branchNameInput" className="py-2 m-0 text-101 text-color5">
//                 Branch Name
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="branchNameInput"
//                 name="branch_name"
//                 type="text"
//                 disabled
//                 value={bankDetails?.branch_name || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="micrInput" className="py-2 m-0 text-101 text-color5">
//                 MICR Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="micrInput"
//                 name="micr_code"
//                 type="text"
//                 disabled
//                 value={bankDetails?.micr_code || ''}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="accountNumberInput" className="py-2 m-0 text-101 text-color5">
//                 Bank Account Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4"
//                 id="accountNumberInput"
//                 placeholder="**** **** 0000"
//                 name="bank_account_number"
//                 value={bankN}
//                 onChange={(e) => setBankN(e.target.value)}
//               />
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col lg={3}>
//               <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
//                 Confirm Bank Account Number
//               </Label>
//             </Col>
//             <Col lg={6}>
//               <Input
//                 className="nominee-form-input text-color1 text-4"
//                 id="confirmAccountNumberInput"
//                 placeholder="**** **** 0000"
//                 name="confirm_bank_account_number"
//                 value={bankC}
//                 onChange={(e) => setBankC(e.target.value)}
//               />
//             </Col>
//           </Row>

//           {statusCheck?.verified === false && (
//             <>
//               <Row className="mb-3">
//                 <Col lg={3}>
//                   <Label htmlFor="proofTypeSelect" className="py-2 m-0 text-101 text-color5">
//                     Select Proof
//                   </Label>
//                 </Col>
//                 <Col lg={6}>
//                   <Input
//                     className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                     id="proofTypeSelect"
//                     name="bank_proof"
//                     type="select"
//                     value={proofType}
//                     onChange={(e) => setProofType(e.target.value)}
//                   >
//                     <option key="0" value="" disabled>
//                       Select Type
//                     </option>
//                     <option key="1" value="Bank Statement">
//                       Bank Statement
//                     </option>
//                     <option key="2" value="Personalized Cheque">
//                       Personalized Cheque
//                     </option>
//                     <option key="3" value="Bank Passbook">
//                       Bank Passbook
//                     </option>
//                     <option key="4" value="Bank Letter">
//                       Bank Letter
//                     </option>
//                   </Input>
//                 </Col>
//               </Row>
//               <Row className="mb-3">
//                 <Col lg={3}>
//                   <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
//                     Upload Proof File
//                   </Label>
//                 </Col>
//                 <Col lg={6}>
//                   <Input
//                     className="nominee-form-input text-color1 text-4"
//                     type="file"
//                     id="fileInput"
//                     placeholder="No File Selected"
//                     name="file"
//                     onChange={(e) => setFile(e.target.files[0])}
//                   />
//                 </Col>
//               </Row>
//             </>
//           )}
//           {

//           }
// <button
//             className="btn me-3 additional-page-button-save mt-2"
//             type="submit"
//             onClick={handleBank}
//           >
//             Authenticate
//           </button>

//         </>
//       )}
//     </>
//   );
// };

// export default BankDetails;


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  pennyDrop,
  checkModificationStatus,
  getBankMasterInfo,
} from "../../../helpers/fakebackend_helper";
import ShowBankDetails from "./ShowBankDetails";
import { Col, Row, Label, Input } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { MOD_EKYC_NEW } from "../../../helpers/url_helper";
import Loader from "../../../Components/Common/Loader";

const BankDetails = (props) => {

  const { data, activeTab,Balance} = props;

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const [addBank1, setAddBank1] = useState(false);
  const [addBank2, setAddBank2] = useState(false);
  const [ifsc, setIfsc] = useState('');
  const [bankN, setBankN] = useState('');
  const [doc_password, SetdocPassword] = useState('');
  const [bankC, setBankC] = useState('');
  const [bankDetails, setBankDetails] = useState({});
  const [statusCheck, setCheckstatue] = useState({});
  const [nameCheck, setName] = useState(false);
  const [fullBank, setFullbank] = useState([]);
  const [fullBankPrimary, setFullbankPrimary] = useState([]);
  const [proofType, setProofType] = useState('');
  const [file, setFile] = useState(null);
  const [state, setState] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [loading, setLoading] = useState(false)
  console.log(props.data[0]?.FIBSACCT, 'propspropspropsprops');

  const ProfileName = props.data[0]?.FIBSACCT?.trim()



  useEffect(() => {

    let intervalId;

    const callApi = () => {
      checkModificationStatus({
        client_code: client_code,
        entry_type: 'primary_bank',

      })

        .then((response) => {
          if (response.status == true && response?.data.modification.length > 0) {
            setFullbankPrimary(response?.data.modification)

            if (response?.data.modification[0]?.is_esign == 1) {
              setShouldFetch(false)
            }

          }
          else {
            setFullbankPrimary([])

          }

        })
        .catch((err) => {
          toast('something went wrong')

          // setShouldContinueApi(false)
        });


    };

    if (props.activeTab === "5" && shouldFetch) {
      intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
    }

    return () => {
      clearInterval(intervalId); // Cleanup on unmount or condition change
    };
  }, [props.activeTab === "5", shouldFetch]);

  useEffect(() => {
    setName(false)
    setCheckstatue({});
    setIfsc('');
    setBankN('');
    SetdocPassword('');
    setBankC('');
    setBankDetails([]);
    setAddBank1(false);
    setFile(null)
    setProofType('')
    if (activeTab === "5") {
      apicall()
    }
  }, [activeTab, addBank2]);
  useEffect(() => {
    setName(false)

    setCheckstatue({});
    setIfsc('');
    setBankN('');
    SetdocPassword('');
    setBankC('');
    setBankDetails([]);
    setAddBank1(false);
    setFile(null)
    setProofType('')
    if (activeTab === "5") {
      apicall(1)
    }
  }, [activeTab]);

  useEffect(() => {
    setShouldFetch(false)
  }, [props.activeTab === "5"])

  useEffect(() => {
    setState(0)

    checkModificationStatus({
      client_code: client_code,
      entry_type: "primary_bank",
    })
      .then((response) => {
        if (response.status == true && response?.data.modification.length > 0) {
          setFullbankPrimary(response?.data.modification)
          if (response?.data.rejection.length) {
            setFullbankPrimary([])
          }

        }
        else {
          setFullbankPrimary([])

        }

      })
      .catch((err) => {
        // Handle error
      });
  }, [props.activeTab === "5", addBank2, state])

  const apicall = (id) => {
    { id == 1 && setLoading(true) }
    checkModificationStatus({
      client_code: client_code,
      entry_type: "bank",
    })
      .then((response) => {
        { id == 1 && setLoading(false) }
        if (response.status === true && response?.data?.modification?.length > 0) {
          setFullbank(response?.data?.modification)
          setAddBank2(true)
        } else {
          setAddBank2(false)
          setFullbank([])
          // setAddBank1(false);

        }
      })
      .catch((err) => {
        { id == 1 && setLoading(false) }

      });
  }

  const addBank = () => {
    setAddBank1(true);
    setIfsc('');
    setBankDetails([]);
    setCheckstatue({});
    setBankN('');
    SetdocPassword('');
    setBankC('');
  };

  const calculateNameMatchPercentage = (name1, name2) => {


    const distance = levenshteinDistance(name1, name2);
    const maxLength = Math.max(name1.length, name2.length);
    const matchPercentage = (1 - distance / maxLength) * 100;
    console.log('matchPercentage', matchPercentage);
    return matchPercentage.toFixed(2);
  };
  const levenshteinDistance = (s1, s2) => {
    const m = s1.length;
    const n = s2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) {
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          dp[i][j] = Math.min(
            dp[i - 1][j - 1] + (s1[i - 1] !== s2[j - 1] ? 1 : 0),
            dp[i - 1][j] + 1,
            dp[i][j - 1] + 1
          );
        }
      }
    }

    return dp[m][n];
  };

  const handleBank = () => {
    setName(false)

    setProofType('')
    setFile(null)
    if (ifsc.length === 0) {
      toast('Please enter IFSC code');
      return;
    }
    if (bankN.length === 0) {
      toast('Please enter account number');
      return;
    }
    if (bankC.length === 0) {
      toast('Please enter confirm account number');
      return;
    }


    if (bankC !== bankN) {
      toast('Your bank account number and confirm account number do not match');
      return;
    }
    if (bankDetails.length === 0) {
      toast('your bank name and branch name not fetch ');
      return;
    }
    if (bankN.length < 8) {
      toast('please enter valid Account number');
      return;
    }
    else {
      setLoading(true)
      pennyDrop({
        beneficiary_account_no: bankC,
        beneficiary_ifsc: ifsc,
      })
        .then((response) => {
          const nameMatchPercentage = calculateNameMatchPercentage(ProfileName.toLowerCase(), response?.verified ? response?.beneficiary_name_with_bank.toLowerCase() : 'khjnhnj');
          if (response?.verified && nameMatchPercentage >= 40) {
            setLoading(false)
            toast('Your bank account has been verified successfully')
            setCheckstatue(response);
            const formData = new FormData();
            formData.append('client_code', client_code);
            formData.append('client_token', client_token);
            formData.append('entry_type', 'bank');
            formData.append('new[bank_branch]', bankDetails.branch_name);
            formData.append('new[bank_account_number]', bankN);
            formData.append('new[bank_name]', bankDetails.bank_name);
            formData.append('new[ifsc_code]', ifsc);
            formData.append('new[micr_number]', bankDetails.micr_code);
            formData.append('old[bank_branch]', 'AA');
            formData.append('old[bank_account_number]', data[0]?.ACNUMBER);
            formData.append('old[bank_name]', data[0]?.BANK_NAME);
            formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
            formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
            formData.append('doc_json', JSON.stringify(response));
            formData.append('modify_key[]', 'bank_branch');
            formData.append('modify_key[]', 'bank_account_number');
            formData.append('modify_key[]', 'bank_name');
            formData.append('modify_key[]', 'ifsc_code');
            formData.append('modify_key[]', 'micr_number');
            formData.append('doc_type', 'PD');
            formData.append('doc_id', response?.id);

            axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer your_token',
              },
            })

              .then(response => {
                if (response.status == false || response.message == 'You have already requested for bank updation.') {
                  setAddBank1(false)
                  apicall()
                  setLoading(false)

                  return
                }
                if (response.status) {
                  setAddBank1(false)
                  apicall()
                  setLoading(false)

                  return
                }
                else {
                  toast(response.message)
                  setLoading(false)

                }

              })
              .catch(error => {
                setLoading(false)

              });
            // setAddBank1(false);

          }

          else {
            setName(true)
            toast('We were unable to verify your bank account, please upload proof')
            setCheckstatue(response);
            setLoading(false)
          }

        })
        .catch((err) => {
          toast.error('something went wrong')
          setLoading(false)

        });
    }
  };
  const handleBank2 = () => {

    const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxFileSize = 2 * 1024 * 1024;
    if (proofType == '') {
      toast('Please select  bank proof type');
      return;
    }
    if (file == null) {
      toast('Please select bank proof');
      return;
    }
    if (!allowedFileTypes.includes(file.type)) {
      toast('Invalid file type. Only PDF, PNG, and JPEG are allowed.');
      return;
    }

    if (file.size > maxFileSize) {
      toast('File size exceeds 2 MB.');
      return;
    }
    else {
      setLoading(true)
      const formData = new FormData();
      formData.append('client_code', client_code);
      formData.append('client_token', client_token);
      formData.append('entry_type', 'bank');
      formData.append('new[bank_branch]', bankDetails.branch_name);
      formData.append('new[bank_account_number]', bankN);
      formData.append('new[bank_name]', bankDetails.bank_name);
      formData.append('new[ifsc_code]', ifsc);
      formData.append('new[micr_number]', bankDetails.micr_code);
      formData.append('old[bank_branch]', 'AA');
      formData.append('old[bank_account_number]', data[0]?.ACNUMBER);
      formData.append('old[bank_name]', data[0]?.BANK_NAME);
      formData.append('old[ifsc_code]', data[0]?.CIFSCCODE);
      formData.append('old[micr_number]', data[0]?.CMICRBANKCODE);
      // formData.append('doc_json',statusCheck);
      formData.append('modify_key[]', 'bank_branch');
      formData.append('modify_key[]', 'bank_account_number');
      formData.append('modify_key[]', 'bank_name');
      formData.append('modify_key[]', 'ifsc_code');
      formData.append('modify_key[]', 'micr_number');
      formData.append('doc_type', 'Bank_Proof');
      formData.append('doc_password', doc_password);
      formData.append('doc_id', statusCheck?.id);
      formData.append('proof_type', proofType);
      formData.append('doc_path', file);


      axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer your_token',
        },
      })

        .then(response => {
          if (response.status == false || response.message == 'You have already requested for bank updation.') {
            setAddBank1(false)
            apicall()
            setLoading(false)


            return
          }
          if (response.status) {

            setAddBank1(false)
            apicall()
            setLoading(false)

            return
          }
          else {
            setLoading(false)

            toast(response.message)
          }

        })
        .catch(error => {
          setLoading(false)
          toast.error('something went wrong')

        });
    }
  };


  const Addbankname = fullBank?.filter(item => item?.modify_key === "bank_name");
  const Addbankname1 = fullBank?.filter(item => item?.modify_key === "bank_account_number");
  const Addbankname2 = fullBank?.filter(item => item?.modify_key === "ifsc_code");

  const handleStateChange = (newState) => {
    setState(newState);
  };

  const handleStateChange1 = (value) => {
    setShouldFetch(value);
  };
  const handlePaste = (e) => {
    e.preventDefault();
    // toast.error('Pasting is not allowed!');
  };

  const handleIfscChange = (e) => {
    const value = e.target.value;
    setIfsc(value);

    // Clear the other input field if the IFSC code becomes "0"
    if (value.length == "0") {
      setBankDetails({})
      setBankN('')
      setBankC('')
      setName(false)
    }
  };

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {addBank1 === false ? (
        <>
          <ShowBankDetails dd1={data} addBank={addBank} addBank2={addBank2} fullBankPrimary={fullBankPrimary} state={state} onStateChange={handleStateChange} apicheck={handleStateChange1} Balance={Balance} />
          {
            fullBank.length > 0 &&
            <div>
              {/* <h4 className="text-16 text-color1 mt-3">Primary Account</h4> */}
              {
                <Row className="nominee-details mt-3 mx-0 py-3">
                  {/* <Col lg={1} xs={3}>
                <div className="bank-details-image-div me-4">
                  <img src={hdfc} className="bank-details-image" />
                </div>
              </Col> */}
                  <Col lg={3} xs={6}>
                    {/* <img src={hdfc} className="bank-details-image" /> */}
                    <h2 className="text-101 text-color7">{Addbankname[0]?.new_value}</h2>
                    <div className="d-flex">
                      <h4 className="text-11 text-color9 me-2 bank-details-badge2 p-1 mb-2">
                        Other{" "}
                      </h4>
                      <h4 className="text-11 text-color9">
                        {/* <i className="ri-edit-line fs-5 text-color5" /> */}
                        {/* <EditChages /> */}
                      </h4>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <h2 className="text-11 text-color9 mb-2">Bank Account Number</h2>
                    <h4 className="text-4 text-color1">{Addbankname1[0]?.new_value}</h4>
                  </Col>

                  <Col lg={3}>
                    <h2 className="text-11 text-color9 mb-2">IFSC Code</h2>
                    <h4 className="text-4 text-color1">{Addbankname2[0]?.new_value.toUpperCase()}</h4>
                  </Col>
                  <Col lg={3}>
                    <h2 className="text-11 text-color9 mb-2">Status</h2>
                    {
                      Addbankname[0]?.is_send_to_ld == 1 ?
                        <h4 className="text-4 " style={{ color: 'green' }}>{'In progress'}</h4>
                        :
                        Addbankname[0]?.is_esign == 0 ?
                          <h4 className="text-4 text-color10">{'In progress'}</h4>
                          :
                          null

                    }

                  </Col>

                </Row>}
            </div>
          }

        </>
      ) : (
        <>
          <button
            type="button"
            className="additional-page-button-cancel me-2"
            onClick={() => {
              setAddBank1(false);
            }}
          >
            Go Back
          </button>
          <Row className="mb-3">
            <Col lg={3}>
              <Label htmlFor="ifscInput" className="py-2 m-0 text-101 text-color5">
                IFSC Code
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                id="ifscInput"
                name="ifsc_code"
                type="text"
                value={ifsc}
                maxLength={11}
                onChange={handleIfscChange}
              // onChange={(e) => setIfsc(e.target.value)}
              />
            </Col>
            <Col lg={3} className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-primary me-3 additional-page-button-save"
                onClick={() => {
                  if (ifsc.length === 0) {
                    toast.error('Please enter IFSC code');
                    return
                  }
                  if (ifsc.length < 9) {
                    toast.error('Please enter valid IFSC code');
                    return
                  }
                  else {
                    getBankMasterInfo({ ifsc_code: ifsc })
                      .then((response) => {
                        if (response.status === true) {
                          if (response.data[0]?.bank_name.length > 2) {
                            setBankDetails(response.data[0]);
                          } else {
                            toast.error('Invalid IFSC code')
                          }
                        }
                      })
                      .catch((err) => {
                        toast(err?.message || 'Something went wrong')
                      });
                  }
                }}
              >
                Fetch
              </button>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={3}>
              <Label htmlFor="bankNameInput" className="py-2 m-0 text-101 text-color5">
                Bank Name
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                id="bankNameInput"
                name="bank_name"
                type="text"
                disabled
                value={bankDetails?.bank_name || ''}
              />
            </Col>
          </Row>
          <Row className="mb-3 d-none">
            <Col lg={3}>
              <Label htmlFor="branchNameInput" className="py-2 m-0 text-101 text-color5">
                Branch Name
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                id="branchNameInput"
                name="branch_name"
                type="text"
                disabled
                value={bankDetails?.branch_name || ''}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={3}>
              <Label htmlFor="micrInput" className="py-2 m-0 text-101 text-color5">
                MICR Number
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                id="micrInput"
                name="micr_code"
                type="text"
                disabled
                value={bankDetails?.micr_code || ''}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={3}>
              <Label htmlFor="accountNumberInput" className="py-2 m-0 text-101 text-color5">
                Bank Account Number
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4"
                id="accountNumberInput"
                placeholder="**** **** 0000"
                name="bank_account_number"
                value={bankN}
                onPaste={handlePaste}
                onChange={(e) => setBankN(e.target.value)}
                maxLength={18}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={3}>
              <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
                Confirm Bank Account Number
              </Label>
            </Col>
            <Col lg={6}>
              <Input
                className="nominee-form-input text-color1 text-4"
                id="confirmAccountNumberInput"
                placeholder="**** **** 0000"
                name="confirm_bank_account_number"
                value={bankC}
                onPaste={handlePaste}
                onChange={(e) => setBankC(e.target.value)}
                maxLength={18}
              />
            </Col>
          </Row>

          {nameCheck == true && (
            <>
              <Row className="mb-3">
                <Col lg={3}>
                  <Label htmlFor="proofTypeSelect" className="py-2 m-0 text-101 text-color5">
                    Select Proof
                  </Label>
                </Col>
                <Col lg={6}>
                  <Input
                    className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                    id="proofTypeSelect"
                    name="bank_proof"
                    type="select"
                    value={proofType}
                    onChange={(e) => setProofType(e.target.value)}
                  >
                    <option key="0" value="" disabled>
                      Select Type
                    </option>
                    <option key="1" value="Bank Statement">
                      Bank Statement
                    </option>
                    <option key="2" value="Personalized Cheque">
                      Personalized Cheque
                    </option>
                    <option key="3" value="Bank Passbook">
                      Bank Passbook
                    </option>
                    <option key="4" value="Bank Letter">
                      Bank Letter
                    </option>
                  </Input>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={3}>
                  <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
                    Upload Proof File
                  </Label>
                </Col>
                <Col lg={6}>
                  <Input
                    className="nominee-form-input text-color1 text-4"
                    type="file"
                    id="fileInput"
                    placeholder="No File Selected"
                    name="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </Col>

              </Row>

              <Row className="mb-3">
                <Col lg={3}>
                  <Label htmlFor="fileInput" className="py-2 m-0 text-101 text-color5">
                    Password
                  </Label>
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    className="nominee-form-input text-color1 text-4 mt-3"
                    id="nameInput"
                    placeholder="Enter File Password,if any"
                    name="file_Password"
                    maxLength={20}
                    onChange={(e) => SetdocPassword(e.target.value)}
                  // value={validation.values.file_Password}
                  // onBlur={validation.handleBlur}
                  // onChange={validation.handleChange}
                  // invalid={
                  //   validation.errors.file_Password &&
                  //     validation.touched.file_Password
                  //     ? true
                  //     : false
                  // }
                  />
                </Col>

              </Row>
            </>
          )}
          {
            nameCheck == false ?
              <button
                className="btn me-3 additional-page-button-save mt-2"
                type="submit"
                onClick={handleBank}
              >
                Authenticate
              </button>
              :
              <button
                className="btn me-3 additional-page-button-save mt-2"
                type="submit"
                onClick={handleBank2}
              >
                Save
              </button>
          }


        </>
      )}
    </>
  );
};

export default BankDetails;



