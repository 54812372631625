import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import Share from "./Models/share/Share";
import { referAndEarnApi } from "../../helpers/fakebackend_helper";

const Widget2 = () => {
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const [copy, setCopy] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await referAndEarnApi({ code: client_code })
        .then((response) => {
          setValue(response);
        })
        .catch((err) => {});
    };
    fetchData();
  }, [client_code]);
  console.log(client_code);
  const myFunction = (data) => {
    navigator.clipboard.writeText(data);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };
  const addFundHandler = () => {
    window.open(
      `https://bigul.co/terms-and-conditions`,
      "_blank"
    );
  };
  ////console.log(value)
  return (
    <Col xl={3} lg={6} md={6} sm={6} className="">
      <Card className="bg-primary card-invest">
        <CardBody style={{ paddingTop: "4px", marginBottom: "10px" }}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <div className="d-flex">
                <p className="text-1 mb-1 text-color3 ">
                  Refer & Earn ₹ 300 per referral
                </p>
                <button
                  style={{ background: "transparent", border: "0" }}
                  to="https://bigul.co/terms-and-conditions"
                  className="t-and-c"
                  onClick={addFundHandler}
                >
                  <p
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Know Terms & Conditions"
                  >
                    * T&C
                  </p>
                </button>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control text-4 text-color4"
                  placeholder={
                    !copy && value !== ""
                      ? `${value.link}`
                      : !copy && value === ""
                      ? "Loading...."
                      : "Copied!"
                  }
                  disabled
                  style={{
                    height: "28px",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                />
                {/* <button
                  className="input-group-text text-5 ref-and-earn-button text-uppercase"
                  onClick={() => {
                    myFunction("Joey#9278");
                  }}
                >
                  Copy & Share
                </button> */}
                <Share data={value.link} />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Widget2;
